.has-tooltip {
  position: relative;
  
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}

.tooltip {
  position: absolute;
  z-index: 10;
  display: inline-block;
  width: 100%;
  min-width: 100px;
  padding: 5px 5px;
  background-color: rgba(#000, .8);
  border-color: rgba(#000, .8);
  border-radius: 4px;
  color: #fff;
  font-size: rem(16);
  line-height: 1;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity .4s ease;
  
  &:after {
    content: "";
    position: absolute;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: inherit;
    border-left-color: transparent;
  }
}






.tooltip--top {
  left: 50%;
  bottom: calc(100% + 2px);
  transform: translateX(-50%);
  
  &:after {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-top-color: inherit;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

.tooltip--left {
  top: 50%;
  left: calc(-100% - 10px);
  transform: translateY(-50%);
  
  &:after {
    top: 50%;
    left: calc(100% - -5px);
    transform: translateY(-50%);
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: inherit;
  }
}

.tooltip--right {
  top: 50%;
  right: calc(-100% - 10px);
  transform: translateY(-50%);
  
  &:after {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-top-color: transparent;
    border-right-color: inherit;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

.tooltip--bottom {
  top: calc(100% + 2px);
  left: 50%;
  transform: translateX(-50%);
  
  &:after {
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: inherit;
    border-left-color: transparent;
  }
}

.tooltip--lg {
  min-width: 160px;
  padding: 15px 20px;
}

.tooltip--md {
  min-width: 140px;
  padding: 10px 15px;
}