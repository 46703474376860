////
/// @group _partials/view-size.scss
////


// scss
// ================================

.view-size {
	position: relative;
	max-width: $lg;
	max-width: calc( #{$lg} + 3rem );
	padding: 0 1.5rem;
	margin: 0 auto;
	@include clearfix;
}

// отображение
.view-size {
	&--no-gap {
		max-width: $lg;
	}
}
