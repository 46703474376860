.button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 9px 24px 7px;
  margin-bottom: 0;
  font-size: rem(16);
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  outline: none;
  z-index: 1;
  transition: .3s ease;
  
  &--def {
    min-width: 200px;
    background-color: transparent;
    color: #fff;
    border-style: solid;
    border-width: 2px;
    border-image: url('#{$mediaPath}css/pic/svgi/border.svg') 2 fill stretch;
    border-radius: 4px;
    overflow: hidden;
    transition: all .3s ease;
    
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      border-radius: 4px;
      background-image: linear-gradient(to right bottom, rgba(67, 184, 111, .3) 30%, rgba(0, 181, 239, .3));
      opacity: 0;
      transition: opacity .3s ease;
      overflow: hidden;
    }

    .ie &,
    .edge & {
      border-radius: 0;
      border-image-slice: 2;
      border-image-source: linear-gradient(to right, rgb(67, 184, 111) 30%, rgb(0, 181, 239));
      
      &:after {
        border-radius: 0;
      }
    }
    
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
  
  &:active {
    transform: translateY(6px);
  }
}