////
/// @group _partials/view-section.scss
////


// scss
// ================================

.view-section {
	position: relative;
	padding: rem(40) 0 rem(30);

	&__heading {
		text-align: center;

		&[data-init="inviewAnimate"] {
			opacity: 0;

			&.animated {
				opacity: 1;
			}
		}
	}

	&__title {
		display: block;
		margin: 0 0 rem(25) 0;
		color: #fff;
		font-size: rem(36);
		font-weight: 500;

		&--inverse {
			color: #353535;
		}

		@include media($def) {
			font-size: rem(60);
		}
	}

	&__subtitle {
		color: #fff;
		font-size: rem(20);
		font-weight: 100;

		&--inverse {
			color: #353535;
		}

		@include media($def) {
			font-size: rem(26);
		}
	}

	&__content {}


	&--media {
		height: auto;
		min-height: 100vh;
		overflow: hidden;

		.page--about & {
			min-height: 100vh;

			@include media($def) {
				min-height: 780px;
			}
		}

		& > .view-size,
		& > .view-section__content {
			position: relative;
			z-index: 2;
		}

		.bg-vid {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			z-index: 0;
			min-width: 100%;
			min-height: 100%;

			background-color: transparent;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: cover;

			&::-webkit-media-controls-enclosure {
				display: none !important;
			}
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: rgba(#000, .5);

			.page--about & {
				background-color: rgba(#000, .5);
			}
		}
	}

	&--main {
		height: auto;
		min-height: 100vh;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;

		.view-section {
			&__heading {
				margin-top: 35vh;
				color: #fff;

				@include media($def) {
					margin-top: 30vh;
				}
			}

			&__title {
				margin: 0 0 rem(12) 0;
				font-weight: 700;
				text-transform: uppercase;
			}

			&__subtitle {
				display: block;
				max-width: 740px;
				margin: 0 auto;
				font-weight: 300;
			}
		}
	}

	&--advantages {
		height: auto;
		min-height: 100vh;
		padding: rem(30) 0 rem(85) 0;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		background-attachment: fixed;

		.view-section {
			&__heading {
				display: block;
				margin-bottom: rem(46);
			}

			&__title {
				display: block;
				margin-bottom: rem(30);
				color: #9ea1a6;
				font-size: rem(36);
				font-weight: 700;

				@include media($def) {
					font-size: rem(60);
				}
			}

			&__subtitle {
				display: block;
				width: 100%;
				max-width: 700px;
				margin: 0 auto;
				color: #a0a3a8;
			}
		}
	}

	&--infographic {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		//min-height: 180vh;

		//min-height:0;
		//height: calc(100vh - 120px);

		min-height: 860px;
		padding-top: rem(30);

		@include media($md) {
			min-height: 950px;
		}

		.view-section {
			&__heading {
				position: relative;
				z-index: 3;
			}

			&__content {
				width: 90%;
				margin: 0 auto;
			}
		}
	}

	&--management {
		padding-top: rem(60);
		background-color: #fff;
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 0;
			display: block;
			height: 149px;
			background-color: #e9eaf1;
			box-shadow: 0 -1px 0 #ddd;
			border-top: 3px solid #fff;
			border-bottom: 3px solid #fff;
			pointer-events: none;

			/*@include media(338px) {
				height: 144px;
			}
			@include media(560px) {
				height: 163px;
			}

			@include media(676px) {
				height: 144px;
			}

			@include media($md) {
				height: 163px;
			}

			@include media($def) {
				height: 178px;
			}

			@include media($lg) {
				height: 172px;
			}*/
		}

		.view-section {
			&__heading {}

			&__title {}

			&__subtitle {
				display: block;
				width: 100%;
				max-width: 540px;
				margin: 0 auto;
			}

			&__content {
				position: relative;
				z-index: 1;
				width: 90%;
				margin: 0 auto;
			}
		}
	}

	&--contacts {
		min-height: initial;
		padding-bottom: rem(50);
		background-color: #fff;

		.page--contacts & {
			padding-top: rem(120);
			padding-bottom: 0;

			@include media($def) {
				padding-top: rem(200);
			}

			@include media(1560px) {
				padding-top: rem(140);
			}
		}
	}

	&--key-dates {
		background-color: #f3f3f3;
	}
}
