.langs {
  &__dropdown {}
  &__link {
    display: inline-block;
    color: #fff;
    font-size: rem(16);
    font-weight: 700;
    text-decoration: none;
    transition: color .3s ease;
    
    &:hover {
      text-decoration: none;
    }
  }
}