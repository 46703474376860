.advantages {
  &__list {
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  &__item {
    display: block;
    min-height: 250px;
    padding: rem(30) rem(50);
    background-color: #253850;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(30%);
    opacity: 0;
    transition: transform .6s ease .2s, opacity .6s ease .2s;

    &.animate-me {
      transform: translateY(0);
      opacity: 1;
    }
    
    & + & {
      margin-top: rem(20);
    }
  }
}