////
/// @group _partials/view-header.scss
////


// scss
// ================================

.view-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	display: block;
	
	padding: rem(10) rem(15);
	background-color: #eaeaea;
	box-shadow: 2px 0 4px rgba(#333, .3);
	transition: .3s ease;
	
	@include media(560px, max) {
		height: 1px;
		min-height: 65px;
		
		._mob-menu-breakpoint {
			display: none;
		}
		
		& > .grid {
			height: 100%;
		}
	}
	
	@include media($def) {
		padding: rem(20) rem(30);
	}

	@include media(1560px) {
		padding: rem(40) rem(50);
	}
	
	.logo {
		position: absolute;
		top: calc(50% + 4px);
		left: rem(15);
		z-index: 1;
		
		@include media($def) {
			left: 50%;
		}
	}

	.navbar__item,
	.link,
	.button,
	.langs__link {
		color: #353535;
	}
	
	.burger {
		span {
			background-color: #353535;
			
			&:before, 
			&:after {
				background-color: #353535;
			}
		}
	}
	
	.svgi--caret-down {
		fill: #353535;
	}
	
	&--transparent {
		padding: rem(40) rem(40) rem(80);
		background-color: transparent;
		box-shadow: none;
		
		.logo--colored {
			transform: translateY(-50%) scale(0);
			
			@include media($def) {
				transform: translate(-50%, -50%) scale(0);
			}
		}
		
		.logo--simple {
			top: calc(50% + 20px);
			transform: translateY(-50%) scale(.75);
			
			@include media(560px) {
				top: calc(50% - 10px);
			}
			
			@include media($def) {
				top: calc(50% + 20px);
				transform: translate(-50%, -50%) scale(1);
			}
		}
		
		.navbar__item,
		.link,
		.button,
		.langs__link {
			color: #fff;
		}

		.burger {
			span {
				background-color: #fff;

				&:before,
				&:after {
					background-color: #fff;
				}
			}
		}

		.svgi--caret-down {
			fill: #fff;
		}

		@include media(560px, max) {
			.question-wrap {
				margin-top: 10px;
			}
		}

		@include media($def) {
			padding: rem(40) rem(50);
		}

		@include media(1560px) {
			padding: rem(60) rem(70);
		}
	}
}
