.mob-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 50vw;
  min-width: 320px;
  max-height: 100vh;
  padding: 0 rem(15);
  overflow-y: auto;
  background-color: rgba(#000, .5);
  opacity: 0;
  transform: translateX(100%);
  transition: .3s ease;
  box-shadow: 0 0 3px 2px rgba(#353535, .3);

  .menu-is-open & {
    opacity: 1;
    transform: translateX(0);
  }

  ._mob-menu-breakpoint {
    display: block;
  }
  
  .burger {
    margin: 20px 0 7px auto;
  }
  
  @include media(560px) {
    ._mob-menu-breakpoint {
      display: none;
    }
  }
  
  &__wrap {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  &__row {
    & + & {
      margin-top: rem(20);
    }
  }
  
  
  &__navbar {
    align-self: center;
    width: 100%;
    margin-bottom: auto;
    
    &-item {
      text-align: center;
    }
    
    &-link {
      display: block;
      padding: 10px 0;
      color: #fff;
      font-size: rem(16);
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      transition: color .3s ease;
      letter-spacing: .5px;

      &:hover {
        text-decoration: none;
      }

      &.is-active {
        pointer-events: none;

        &[data-hover-text] {
          color: #353535 !important;

          &:after {
            width: 100%;
            top: 10px;
          }
        }
      }
    }
  }
  
  &__question-button {
    width: 100%;
  }
  
  &__langs {
    display: flex;
    justify-content: center;
    
    &-item {
      width: 48%;
      margin: auto;
      border-width: 1px;
      border-style: solid;
      border-bottom: none;
      border-image-slice: 2;
      
      &:nth-child(even) {
        border-image-source: linear-gradient(to right, rgb(0, 181, 239) 30%, rgb(67, 184, 111));
      }
      &:nth-child(odd) {
        border-image-source: linear-gradient(to right, rgb(67, 184, 111) 30%, rgb(0, 181, 239));
      }
    }
    
    &-link {
      position: relative;
      display: block;
      padding: 8px 0;
      color: #fff;
      text-align: center;
      text-decoration: none;
      
      &:hover {
        text-decoration: none;
      }

      &.is-active {
        pointer-events: none;

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          background-image: linear-gradient(to right bottom, rgba(67, 184, 111, .3) 30%, rgba(0, 181, 239, .3));
          opacity: 1;
          transition: opacity .3s ease;
          overflow: hidden;
        }
      }
    }
  }
  
  &__bg-logo {
    position: absolute;
    top: calc(50% - 20px);
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    z-index: 0;
    opacity: .7;
    
    @media only screen and (max-height: 500px) {
      transform: translate(-50%, -50%) scale(.8);
      opacity: .4;
    }
  }
}