.form {
	&__group {

		&--textarea {
			@include media($md) {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				height: 100%;

				.ios &,
				.safari &,
				.mac &,
				.maclike &,
				.ipad &,
				.iphone & {
					display: block;
					height: 290px;

					@include media($lg) {
						height: 276px;
					}
				}
			}
		}

		&--submit {
			margin-top: rem(40);
			text-align: center;

			&.js-init {
				opacity: 0;
				transition: opacity .2s ease;

				.mfp-content &,
				&.animated {
					opacity: 1;
				}
			}
		}
	}

	&__gvertical {
		.form__group + .form__group {
			margin-top: rem(30);

			@include media($md) {
				margin-top: rem(20);
			}
		}
	}

	&__hint {
		display: block;
		margin-top: rem(10);
		color: #fff;
		font-size: rem(12);
		font-weight: 300;
		opacity: .37;
		letter-spacing: .5px;
	}

	&--contacts {
		display: block;
		width: 100%;
		max-width: 1032px;
		margin: 0 auto;
	}
}