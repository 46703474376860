.media {
  
  &__icon {
    flex-shrink: 0;
    margin-right: 15px;
  }
  
  &__content {
    flex-grow: 1;
  }
  
  &--obj {
    display: flex;
    align-items: center;
  }
}