.social {
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  &__item {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    
    svg {
      display: block;
      max-width: 25px;
      max-height: 25px;
      margin: 0 auto;
      fill: #b6b6b6;
      transition: fill .3s ease;
    }
    
    @each $soc, $color in (skype, skype), (fb, facebook), (in, linked-in), (tw, twitter), (ins, instagram) {
      &--#{$soc} {
        &:hover {
          svg {
            fill: map-get($palette-social, '#{$color}');
          }
        }
      }
    }
  }
}