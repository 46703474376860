$color-developer: #fc171f;

._color-developer {
  color: $color-developer;
}

.developer {
  display: flex;
  align-items: center;
  text-decoration: none;

  max-width: 310px;
  margin: 0 auto;

  @include media($def) {
    max-width: initial;
    margin: initial;
  }

  &__icon {
    display: block;
    width: 46px;
    height: 18px;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;
      fill: $color-developer;
    }
  }

  &__content {
    display: block;
    flex-grow: 1;
    margin-left: 10px;
    color: #90898f;
    font-size: rem(12.8);
    font-weight: 300;
    line-height: 1.4;
    transition: color .3s ease;

    @include media($ms) {
      font-size: rem(13);
    }
  }

  &:hover {
    text-decoration: none;

    .developer__icon {
      transform-origin: center;
      transform: scaleX(-1);
      transition: transform .3s ease;
    }

    .developer__content {
      color: $color-developer;
    }
  }
}