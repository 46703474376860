.infographic {
	&__list {
	}

	&__item {
		text-align: center;

		&:first-child {

			.media-card__title {
				&:after {
					content: '+';
					background-image: linear-gradient(to top, #d3d3d3 40%, #fff 60%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					color: inherit;
					font-size: inherit;
					font-weight: inherit;

					.ie & {
						background-image: none;
						-webkit-background-clip: initial;
						-webkit-text-fill-color: initial;
					}
				}
			}
		}
	}
}