.mouse {
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: none;
  width: 26px;
  height: 44px;
  border: 1px solid #fff;
  border-radius: 20px;
  cursor: pointer;
  
  @media only screen and (min-height: 840px) and (min-width: 1025px) {
    display: block;
  }
  
  & > span {
    content: '';
    position: absolute;
    top: 10px;
    left: calc(50% - 2px);
    z-index: 1;
    display: block;
    width: 5px;
    height: 5px;
    background-color: #fff;
    border-radius: 50%;
    animation: translate 2s infinite;
  }
}