.founded {
  font-size: rem(80);
  position: relative;
  max-width:4em;
  z-index:2;

  svg {
    fill: #fff;
    opacity: .2;
    overflow: visible;
    position: absolute;
    top: -1em;
    left: -2em;
    right: -2em;
    bottom:-1em;
    margin: auto;
    height: 4em;
    width: 6em;

    .moz & {
      opacity: .4;
    }
  }
  
  &__date {
    color: #fff;
    font-size: 1em;
    font-weight: 700;
    text-align: center;
    text-shadow: 1px 1px 0 #353535;
    

  }
  
  &__text {
    color: #fff;
    font-size: .25em;
    font-weight: 100;
    text-align: center;
    text-shadow: 1px 1px 0 #353535;
  }
  @include media(1920px){
    font-size:rem(160);
  }
}