.dropdown {
  position: relative;
  
  &__trigger {
    cursor: pointer;
  }
  
  &__content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: visibility .2s, opacity .2s;
    z-index: 1;
  }
  
  &--up &__content {
    bottom: 100%
  }
  
  &--down &__content {
    top: 100%
  }

  &.is-open &__content,
  &:not([data-init="dropdown"]):hover &__content {
    visibility: visible;
    opacity: 1;
  }
}