.popup {
  position: relative;
  
  &__heading {
    margin-bottom: rem(30);
  }
  
  &__title {
    display: block;
    margin-bottom: rem(20);
    color: #fff;
    font-size: rem(28);
    font-weight: 500;
    text-align: center;
    letter-spacing: .5px;
    
    @include media($ms) {
      font-size: rem(60);
    }
  }
  
  &__subtitle {
    display: block;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    color: #fff;
    font-size: rem(16);
    font-weight: 100;
    text-align: center;

    @include media($ms) {
      font-size: rem(26);
    }
  }
  
  &__content {}
  
  &--question {
    background-color: transparent;
  }
}