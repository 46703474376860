.contacts {
  &__types {
    display: block;
    width: 100%;
    max-width: 1032px;
    margin: 0 auto rem(40);
    text-align: center;

    @include media($ms) {
      text-align: left;
    }
    
    .text {
      display: block;
      //margin-bottom: rem(20);
      font-size: rem(20);
      font-weight: 100;

      @include media($ms) {
        margin-bottom: rem(20);
      }

      @include media(940px) {
        font-size: rem(26);
      }
    }
    
    .link {
      display: inline-block;
      color: #fff;
      font-size: rem(26);
      font-weight: 700;
      text-decoration: none;
      
      &:hover {
        text-decoration: none;
      }
      
      @include media(940px) {
        font-size: rem(40);
      }
    }
    
    .js-init {
      opacity: 0;
      transition: opacity .2s ease;
      
      &.animated {
        opacity: 1;
      }
    }
  }
  
  &__msg {
    display: block;
    width: 100%;
    max-width: 630px;
    margin: 0 auto;
    margin-bottom: rem(40);
    color: #fff;
    font-size: rem(20);
    font-weight: 100;
    text-align: center;
    
    @include media($md) {
      font-size: rem(26);
    }
  }
  
  &__form {}
  
  &__table {
    margin-top: rem(40);
  }
}