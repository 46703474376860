.key-dates {

  &__list {
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: rem(50) auto;
  }

  &__item {
    display: block;
    min-height: 250px;
    padding: rem(15) rem(16) rem(25);
    background-color: #fff;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(30%);
    opacity: 0;
    transition: transform .6s ease .2s, opacity .6s ease .2s;

    &.animate-me {
      transform: translateY(0);
      opacity: 1;
    }

    & + & {
      margin-top: rem(20);
    }
    
    @include media($ms) {
      padding: rem(30) rem(50);
    }
  }
  
  &__date {
    display: block;
    color: #353535;
    font-size: rem(50);
    font-weight: 700;
  }
  
  &__msg {
    display: block;
    width: 100%;
    margin: 0 auto;
    color: #353535;
    font-family: "Roboto", sans-serif;
    font-size: rem(16);
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    
    &--top {
      max-width: 750px;

      @include media($ms) {
        font-size: rem(16);
      }
    }
    
    &--bottom {
      max-width: 520px;
      font-size: rem(24);
      font-weight: 500;
      line-height: 1.2;
      
      @include media($sm) {
        font-size: rem(30);
      }
    }
  }
}