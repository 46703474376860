.management {
	&__list {
	}

	&__item {
		text-align: center;
		//opacity: 0;
		//
		//&:nth-child(even) {
		//  transform: translateY(50%);
		//  transition: transform .4s ease-in-out 0.3s, opacity .4s ease-in-out 0.3s;
		//}
		//
		//&:nth-child(odd) {
		//  transform: translateY(-50%);
		//  transition: transform .4s ease-in-out 0.6s, opacity .4s ease-in-out 0.6s;
		//}
		//
		//&.animate-me {
		//  transform: translateY(0);
		//  opacity: 1;
		//}

		video {
			&::-webkit-media-controls-enclosure {
				display: none !important;
			}
		}
	}

	&__trigger-play {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -20px);
		z-index: 1;
		display: inline-block;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		overflow: hidden;
		background-image: url('#{$mediaPath}css/pic/iphone-play.png');
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		cursor: pointer;

		.no-touchevents & {
			display: none !important;
			background-image: none !important;
		}

		.iphone & {
			opacity: 0;
		}
	}
}