////
/// @group _partials/view-wrapper.scss
////


// scss
// ================================

.view-wrapper {
	background-color: #f3f3f3;
	position: relative;
	overflow-x: hidden;
	display: flex;
	flex-wrap: wrap;
	min-width: 100%;
	min-height: 100%;
	transition: filter .3s ease;
	
	.menu-is-open & {
		filter: blur(10px);
	}

	> .view-seo,
	> .view-footer,
	> .view-container {
		@include flexBasisMin( 100% );
	}

	> .view-container,
	> .mm-page {
		order: 0;
	}

	> .view-seo {
		order: 1;
	}

	> .view-footer {
		order: 2;
		align-self: flex-end;
	}
}

body {
	> .mm-page {
		@include flexBasisMin( 100% );
	}
}

.view-seo {
	padding: 2rem 0;
}