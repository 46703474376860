.navbar {
  
  &__list {}
  
  &__item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 13px;
    color: #fff;
    font-size: rem(16);
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    transition: color .3s ease;
    letter-spacing: .5px;
    
    &:hover {
      text-decoration: none;
    }

    &.is-active {
      pointer-events: none;

      &[data-hover-text] {
        color: #353535 !important;

        &:after {
          width: 100%;
        }
      }
    }
  }
}