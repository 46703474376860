.media-card {

	&--horizontal {
		display: flex;
	}

	&--products-types {
		text-align: center;

		.media-card {

			&__aside {
				img {
					margin: 0 auto;
					transition: transform .3s ease;
					backface-visibility: hidden;
					max-height: 135px;
				}
			}

			&__content {
				margin-top: rem(26);
			}

			&__title {
				display: inline-block;
				color: #fff;
				font-size: rem(16);
				font-weight: 700;
				text-transform: uppercase;
				text-decoration: none;
				text-align: center;

				&:hover {
					text-decoration: none;
				}
			}
		}

		&:hover {
			.media-card {
				&__aside {
					img {
						transform: scale(.9);
					}
				}
			}
		}
	}

	&--advantages {
		.media-card {
			&__aside {
				position: relative;
				width: 220px;
				min-width: 130px;
				//min-height: 250px;
				min-height: 180px;

				@include media(md) {
					flex-shrink: 0;
				}

				img,
				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 1;
				}

				img {
					transform: translate(-50%, -50%);
				}

				svg {
					display: inline-block;
					max-width: 170px;
					fill: #4c5b6e;
					transform: translate(-50%, -50%) scale(1.2);
					overflow: visible;

					.moz & {
						transform: scale(1.15);
						top: calc(50% - 95px);
						left: calc(50% - 100px);
					}
				}
			}

			&__content {
				padding-top: rem(15);
				text-align: center;

				@include media($ms) {
					margin-left: rem(50);
					padding-left: rem(40);
					border-left: 1px solid #4c5b6e;
					text-align: left;
				}

				@include media($md) {
					flex-grow: 1;
					padding-left: rem(80);
				}
			}

			&__title {
				display: block;
				margin-bottom: rem(25);
				color: #a2a7b0;
				font-size: rem(26);
				font-weight: 500;

				@include media($def) {
					font-size: rem(30);
				}
			}

			&__desc {
				display: block;
				width: 100%;
				max-width: 505px;
				color: #626c7c;
				font-size: rem(20);
				font-weight: 100;
				line-height: 1.33;

				@include media($def) {
					font-size: rem(24);
				}
			}
		}

		&:nth-child(even) {
			flex-direction: row-reverse;

			@include media($ms, max) {
				flex-direction: column;
			}

			.media-card {
				&__content {
					margin-left: initial;
					padding-left: initial;
					border-left: initial;

					@include media($ms) {
						margin-right: rem(50);
						padding-right: rem(80);
						border-right: 1px solid #4c5b6e;
					}
				}
			}
		}

		@include media($ms, max) {
			flex-direction: column;
			align-items: center;
		}
	}

	&--infographic {
		.media-card {
			&__aside {
				margin-bottom: rem(20);

				img {
					display: block;
					max-width: 100%;
					height: auto;
					margin: 0 auto;
				}
			}

			&__content {
			}

			&__title {
				display: block;
				margin-bottom: rem(10);
				background-image: linear-gradient(to top, #d3d3d3 40%, #fff 60%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				color: #fff;
				font-size: rem(60);
				font-weight: 700;

				.ie & {
					background-image: none;
					-webkit-background-clip: initial;
					-webkit-text-fill-color: initial;
				}
			}

			&__desc {
				color: #fff;
				font-size: rem(24);
				font-weight: 100;
			}
		}
	}

	&--management {

		.media-card {
			&__aside {
				position: relative;
				display: block;
				width: 100%;
				max-width: 90%;
				height: 0;
				padding-top: 128.57%;
				margin: 0 auto 20px;
				background-color: #fff;

				video {
					position: absolute;
					bottom: 0;
					left: 50%;
					z-index: 0;
					transform: translateX(-50%);
					max-width: 100%;
					max-height: 100%;

					&.is-active {
						z-index: 1;
					}
				}
			}

			&__content {
			}

			&__title {
				display: block;
				margin-bottom: rem(8);
				color: #353535;
				font-size: rem(22);
				font-weight: 700;

				@include media($def) {
					font-size: rem(26);
				}
			}

			&__desc {
				display: block;
				width: 100%;
				max-width: 350px;
				margin: 0 auto rem(8);
				color: #353535;
				font-size: rem(16);
				font-weight: 100;

				@include media($def) {
					font-size: rem(20);
				}
			}
		}
	}

	&--key-dates {
		align-items: center;

		.media-card {
			&__aside {
				position: relative;
				width: 220px;
				min-width: 130px;
				min-height: 250px;

				@include media(md) {
					flex-shrink: 0;
				}

				.key-dates__date,
				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 1;
				}

				.key-dates__date {
					top: calc(50% + 5px);
					z-index: 2;
					transform: translate(-50%, -50%);
				}

				svg {
					display: inline-block;
					max-width: 170px;
					fill: #ebebea;
					transform: translate(-50%, -50%) scale(1.2);
					overflow: visible;

					.moz & {
						transform: scale(1.15);
						top: calc(50% - 95px);
						left: calc(50% - 100px);
					}
				}
			}

			&__content {
				padding-top: rem(15);
				text-align: center;

				@include media($ms) {
					margin-left: rem(50);
					text-align: left;
				}

				@include media($md) {
					flex-grow: 1;
				}
			}

			&__desc {
				display: block;
				width: 100%;
				color: #353535;
				font-size: rem(20);
				font-weight: 300;
				line-height: 1.33;

				@include media($def) {
					font-size: rem(24);
				}
			}
		}

		@include media($ms, max) {
			flex-direction: column;
			align-items: center;
		}
	}
}
