.products-types {
  margin-top: rem(100);
  
  &__list {}
  &__item {
    text-decoration: none;
    overflow: hidden;

    [data-hover-text] {
      &:after {
        width: 100%;
        opacity: 0;
        transform: scale(2, 4);
      }
    }
    
    &:hover {
      text-decoration: none;
      
      [data-hover-text] {
        color: #43b86f !important;

        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}