@keyframes translate {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }

  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  40% {
    opacity: .5;
  }
  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}


@keyframes burger {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  20% {
    -webkit-transform: rotate(72deg);
    transform: rotate(72deg)
  }

  40% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg)
  }

  60% {
    -webkit-transform: rotate(216deg);
    transform: rotate(216deg)
  }

  80% {
    -webkit-transform: rotate(288deg);
    transform: rotate(288deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}