////
/// @group _partials/view-footer.scss
////


// scss
// ================================

.view-footer {
	position: relative;
	padding: rem(25) 0;
	background-color: #fff;
	border-top: 1px solid #e5e5e5;

	&__col {
		width: 100%;

		@include media(660px) {
			width: auto;
		}

		&--developer {}

		&--social {
			order: -1;
			width: 100% !important;

			@include media(900px) {
				order: initial;
				width: auto !important;
			}
		}

		&--copyright {
			max-width: 250px;
			margin: 0 auto;
			text-align: center;

			@include media($md) {
				max-width: initial;
				margin: initial;
				text-align: left;
			}
		}
	}
}
