.logo {
  transform-origin: center;
  transition: transform .3s ease;
  
  &__wrap {
    img {
      
    }
  }
  
  &__slogan {
    display: none;
    vertical-align: middle;
    margin-top: -4px;
    color: #353535;
    font-size: rem(40);
    font-weight: 500;
    text-transform: uppercase;
    
    &--left {}
    &--right {}
    
    @include media($lg) {
      display: inline-block;
    }
  }
  
  
  &--simple {
    transform: translateY(-50%) scale(0);

    //.logo__wrap {
    //  display: inline-block;
    //  width: 70px;
    //  height: 55px;
    //
    //  @include media(560px) {
    //    width: 120px;
    //    height: 105px;
    //  }
    //
    //  @include media($def) {
    //    width: 170px;
    //    height: 155px;
    //  }
    //}
    
    @include media($def) {
      transform: translate(-50%, -50%) scale(0);
    }
  }
  
  &--colored {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%) scale(1);
    
    @include media($def) {
      transform: translate(-50%, -50%) scale(1);
    }
    
    .logo__wrap {
      width: 50px;
      height: 50px;
      margin: 0 5px;

      @include media(560px) {
        width: 80px;
        height: 80px;
      }
      
      @include media($def) {
        width: 100px;
        height: 100px;
      }
    }
  }
}