[data-hover-text] {
  position: relative;
  z-index: 1;
  transition: color .3s ease;
  
  &:after {
    content: attr(data-hover-text);
    display: block;
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    color: #01b5f0;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    width: 0;
    transition: width .3s ease, opacity .3s ease, transform .3s ease;
    
    @supports ((background-clip: text) or (-webkit-background-clip: text)) and 
              ((text-fill-color: transparent) or (-webkit-text-fill-color: transparent)) {
      & {
        background-image: linear-gradient(to right, rgb(67, 184, 111) 30%, rgb(0, 181, 239));
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
      }
    }
  }
  
  &:hover {
    color: #353535 !important;
    
    &:after {
      width: 100%;
    }
  }
}