//_HTML\src\sass\_partials\svgi.scss


/* ---------- SVG Icons ---------- */
.svgi {
  display: inline-block;
  vertical-align: middle;
  transition: fill .3s ease;
  
  &--caret-down {
    width: 10px;
    height: 6px;
    fill: #fff;
  }
}