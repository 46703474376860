.burger {
    width: 30px;
    height: 26px;
    cursor: pointer;
}

.burger span {
    display: block;
    position: relative;
    width: 30px;
    height: 2px;
    background-color: #fff;
    top: 12px;
    -webkit-transition: background .25s ease;
    transition: background .25s ease
}

.burger span:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #fff;
    top: -10px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: background .25s ease,top .25s ease .25s,-webkit-transform .25s ease;
    transition: background .25s ease,top .25s ease .25s,-webkit-transform .25s ease;
    transition: background .25s ease,transform .25s ease,top .25s ease .25s;
    transition: background .25s ease,transform .25s ease,top .25s ease .25s,-webkit-transform .25s ease
}

.burger span:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #fff;
    bottom: -10px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: background .25s ease,bottom .25s ease .25s,-webkit-transform .25s ease;
    transition: background .25s ease,bottom .25s ease .25s,-webkit-transform .25s ease;
    transition: background .25s ease,transform .25s ease,bottom .25s ease .25s;
    transition: background .25s ease,transform .25s ease,bottom .25s ease .25s,-webkit-transform .25s ease
}

.burger.is-active span {
    background: transparent!important
}

.burger.is-active span:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0;
    -webkit-transition: top .25s ease,-webkit-transform .25s ease .25s;
    transition: top .25s ease,-webkit-transform .25s ease .25s;
    transition: top .25s ease,transform .25s ease .25s;
    transition: top .25s ease,transform .25s ease .25s,-webkit-transform .25s ease .25s
}

.burger.is-active span:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    bottom: 0;
    -webkit-transition: bottom .25s ease,-webkit-transform .25s ease .25s;
    transition: bottom .25s ease,-webkit-transform .25s ease .25s;
    transition: bottom .25s ease,transform .25s ease .25s;
    transition: bottom .25s ease,transform .25s ease .25s,-webkit-transform .25s ease .25s
}

.burger-overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    width: auto;
    height: 100vh;
    cursor: default;
    margin-right: 0;

    .menu-is-open & {
        display: block;
    }
}