.mfp-bg {
  background: #0a1f34;
  opacity: .9 !important;
}

.mfp-close {
  font-size: 0 !important;
  background-image: url('#{$mediaPath}css/pic/svgi/close-grad.svg') !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: 50% 50% !important;
  cursor: pointer !important;
}

.mfp-iframe-holder {
  .mfp-content {
    position: relative;

    .mfp-close {
      width: 44px;
      height: 44px;
      top: -34px !important;
      right: 0 !important;
      
      @include media($def) {
        top: -22px !important;
        right: -22px !important;
      }
    }
  }
}
