@media only screen and (min-width: 320px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 375px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 412px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 480px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 560px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 640px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 768px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1024px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1280px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1366px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1440px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1560px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1559px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1439px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1365px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1279px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1023px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 767px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 639px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 559px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 479px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 411px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 374px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 319px) {
  /*! $mqpacker hack */
}

html {
  font-family: "Roboto", sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure {
  display: block;
}

figure {
  margin: 1em 0;
}

main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
}

b, strong {
  font-weight: bold;
}

code, kbd, samp {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

a:active, a:hover {
  outline-width: 0;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

abbr[title], dfn[title] {
  cursor: help;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[readonly] {
  cursor: default;
}

input:disabled {
  cursor: not-allowed;
}

html {
  position: relative;
  height: 100vh;
  min-height: 100vh;
  font-size: 17px;
}

@media only screen and (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

*, :after, :before {
  box-sizing: inherit;
  outline: none;
}

a {
  color: #43f;
}

body {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  min-width: 320px;
  background-color: #f3f3f3;
  transition: opacity .3s ease;
}

.no-js body {
  opacity: 1 !important;
  overflow: visible !important;
}

body.menu-is-open {
  overflow: hidden;
}

pre {
  padding: 1.2em;
  tab-size: 4;
  border-width: 0;
  white-space: pre;
  white-space: pre-wrap;
  background: #eee;
  word-wrap: break-word;
}

img {
  max-width: 100%;
  height: auto;
}

small {
  display: inline-block;
  vertical-align: text-bottom;
}

svg {
  pointer-events: none;
}

hr {
  border-width: 0;
  border-top: 1px solid #aaa;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-moz-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-ms-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

input {
  box-shadow: none;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
  opacity: .5;
}

input:-ms-input-placeholder {
  color: #999;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea {
  box-shadow: none;
}

textarea::-webkit-input-placeholder {
  color: #999;
}

textarea::-moz-placeholder {
  color: #999;
  opacity: .5;
}

textarea:-ms-input-placeholder {
  color: #999;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.view-wrapper {
  background-color: #f3f3f3;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  min-height: 100%;
  transition: filter .3s ease;
}

.menu-is-open .view-wrapper {
  filter: blur(10px);
}

.view-wrapper > .view-seo,
.view-wrapper > .view-footer,
.view-wrapper > .view-container {
  width: 100%;
  min-width: 100%;
  flex-basis: 100%;
}

.view-wrapper > .view-container,
.view-wrapper > .mm-page {
  order: 0;
}

.view-wrapper > .view-seo {
  order: 1;
}

.view-wrapper > .view-footer {
  order: 2;
  align-self: flex-end;
}

body > .mm-page {
  width: 100%;
  min-width: 100%;
  flex-basis: 100%;
}

.view-seo {
  padding: 2rem 0;
}

.view-size {
  position: relative;
  max-width: 1280px;
  max-width: calc( 1280px + 3rem);
  padding: 0 1.5rem;
  margin: 0 auto;
}

.view-size:after {
  content: '';
  clear: both;
  display: block;
  height: 0;
}

.view-size--no-gap {
  max-width: 1280px;
}

.view-section {
  position: relative;
  padding: 2.5rem 0 1.875rem;
}

.view-section__heading {
  text-align: center;
}

.view-section__heading[data-init="inviewAnimate"] {
  opacity: 0;
}

.view-section__heading[data-init="inviewAnimate"].animated {
  opacity: 1;
}

.view-section__title {
  display: block;
  margin: 0 0 1.5625rem 0;
  color: #fff;
  font-size: 2.25rem;
  font-weight: 500;
}

.view-section__title--inverse {
  color: #353535;
}

@media only screen and (min-width: 1024px) {
  .view-section__title {
    font-size: 3.75rem;
  }
}

.view-section__subtitle {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 100;
}

.view-section__subtitle--inverse {
  color: #353535;
}

@media only screen and (min-width: 1024px) {
  .view-section__subtitle {
    font-size: 1.625rem;
  }
}

.view-section--media {
  height: auto;
  min-height: 100vh;
  overflow: hidden;
}

.page--about .view-section--media {
  min-height: 100vh;
}

@media only screen and (min-width: 1024px) {
  .page--about .view-section--media {
    min-height: 780px;
  }
}

.view-section--media > .view-size,
.view-section--media > .view-section__content {
  position: relative;
  z-index: 2;
}

.view-section--media .bg-vid {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.view-section--media .bg-vid::-webkit-media-controls-enclosure {
  display: none !important;
}

.view-section--media:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.page--about .view-section--media:after {
  background-color: rgba(0, 0, 0, 0.5);
}

.view-section--main {
  height: auto;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.view-section--main .view-section__heading {
  margin-top: 35vh;
  color: #fff;
}

@media only screen and (min-width: 1024px) {
  .view-section--main .view-section__heading {
    margin-top: 30vh;
  }
}

.view-section--main .view-section__title {
  margin: 0 0 0.75rem 0;
  font-weight: 700;
  text-transform: uppercase;
}

.view-section--main .view-section__subtitle {
  display: block;
  max-width: 740px;
  margin: 0 auto;
  font-weight: 300;
}

.view-section--advantages {
  height: auto;
  min-height: 100vh;
  padding: 1.875rem 0 5.3125rem 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: fixed;
}

.view-section--advantages .view-section__heading {
  display: block;
  margin-bottom: 2.875rem;
}

.view-section--advantages .view-section__title {
  display: block;
  margin-bottom: 1.875rem;
  color: #9ea1a6;
  font-size: 2.25rem;
  font-weight: 700;
}

@media only screen and (min-width: 1024px) {
  .view-section--advantages .view-section__title {
    font-size: 3.75rem;
  }
}

.view-section--advantages .view-section__subtitle {
  display: block;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  color: #a0a3a8;
}

.view-section--infographic {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 860px;
  padding-top: 1.875rem;
}

@media only screen and (min-width: 768px) {
  .view-section--infographic {
    min-height: 950px;
  }
}

.view-section--infographic .view-section__heading {
  position: relative;
  z-index: 3;
}

.view-section--infographic .view-section__content {
  width: 90%;
  margin: 0 auto;
}

.view-section--management {
  padding-top: 3.75rem;
  background-color: #fff;
}

.view-section--management:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  display: block;
  height: 149px;
  background-color: #e9eaf1;
  box-shadow: 0 -1px 0 #ddd;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  pointer-events: none;
  /*@include media(338px) {
				height: 144px;
			}
			@include media(560px) {
				height: 163px;
			}

			@include media(676px) {
				height: 144px;
			}

			@include media($md) {
				height: 163px;
			}

			@include media($def) {
				height: 178px;
			}

			@include media($lg) {
				height: 172px;
			}*/
}

.view-section--management .view-section__subtitle {
  display: block;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
}

.view-section--management .view-section__content {
  position: relative;
  z-index: 1;
  width: 90%;
  margin: 0 auto;
}

.view-section--contacts {
  min-height: initial;
  padding-bottom: 3.125rem;
  background-color: #fff;
}

.page--contacts .view-section--contacts {
  padding-top: 7.5rem;
  padding-bottom: 0;
}

@media only screen and (min-width: 1024px) {
  .page--contacts .view-section--contacts {
    padding-top: 12.5rem;
  }
}

@media only screen and (min-width: 1560px) {
  .page--contacts .view-section--contacts {
    padding-top: 8.75rem;
  }
}

.view-section--key-dates {
  background-color: #f3f3f3;
}

.view-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: block;
  padding: 0.625rem 0.9375rem;
  background-color: #eaeaea;
  box-shadow: 2px 0 4px rgba(51, 51, 51, 0.3);
  transition: .3s ease;
}

@media only screen and (max-width: 559px) {
  .view-header {
    height: 1px;
    min-height: 65px;
  }
  .view-header ._mob-menu-breakpoint {
    display: none;
  }
  .view-header > .grid {
    height: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .view-header {
    padding: 1.25rem 1.875rem;
  }
}

@media only screen and (min-width: 1560px) {
  .view-header {
    padding: 2.5rem 3.125rem;
  }
}

.view-header .logo {
  position: absolute;
  top: calc(50% + 4px);
  left: 0.9375rem;
  z-index: 1;
}

@media only screen and (min-width: 1024px) {
  .view-header .logo {
    left: 50%;
  }
}

.view-header .navbar__item,
.view-header .link,
.view-header .button,
.view-header .langs__link {
  color: #353535;
}

.view-header .burger span {
  background-color: #353535;
}

.view-header .burger span:before, .view-header .burger span:after {
  background-color: #353535;
}

.view-header .svgi--caret-down {
  fill: #353535;
}

.view-header--transparent {
  padding: 2.5rem 2.5rem 5rem;
  background-color: transparent;
  box-shadow: none;
}

.view-header--transparent .logo--colored {
  transform: translateY(-50%) scale(0);
}

@media only screen and (min-width: 1024px) {
  .view-header--transparent .logo--colored {
    transform: translate(-50%, -50%) scale(0);
  }
}

.view-header--transparent .logo--simple {
  top: calc(50% + 20px);
  transform: translateY(-50%) scale(0.75);
}

@media only screen and (min-width: 560px) {
  .view-header--transparent .logo--simple {
    top: calc(50% - 10px);
  }
}

@media only screen and (min-width: 1024px) {
  .view-header--transparent .logo--simple {
    top: calc(50% + 20px);
    transform: translate(-50%, -50%) scale(1);
  }
}

.view-header--transparent .navbar__item,
.view-header--transparent .link,
.view-header--transparent .button,
.view-header--transparent .langs__link {
  color: #fff;
}

.view-header--transparent .burger span {
  background-color: #fff;
}

.view-header--transparent .burger span:before, .view-header--transparent .burger span:after {
  background-color: #fff;
}

.view-header--transparent .svgi--caret-down {
  fill: #fff;
}

@media only screen and (max-width: 559px) {
  .view-header--transparent .question-wrap {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .view-header--transparent {
    padding: 2.5rem 3.125rem;
  }
}

@media only screen and (min-width: 1560px) {
  .view-header--transparent {
    padding: 3.75rem 4.375rem;
  }
}

.view-footer {
  position: relative;
  padding: 1.5625rem 0;
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
}

.view-footer__col {
  width: 100%;
}

@media only screen and (min-width: 660px) {
  .view-footer__col {
    width: auto;
  }
}

.view-footer__col--social {
  order: -1;
  width: 100% !important;
}

@media only screen and (min-width: 900px) {
  .view-footer__col--social {
    order: initial;
    width: auto !important;
  }
}

.view-footer__col--copyright {
  max-width: 250px;
  margin: 0 auto;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .view-footer__col--copyright {
    max-width: initial;
    margin: initial;
    text-align: left;
  }
}

.zoom-in {
  /* animate in */
  /* animate out */
  /* Dark overlay, start state */
  /* animate in */
  /* animate out */
}

.zoom-in .hidden-wrapper {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(0.8);
}

.zoom-in.mfp-ready .hidden-wrapper {
  opacity: 1;
  transform: scale(1);
}

.zoom-in.mfp-removing .hidden-wrapper {
  transform: scale(0.8);
  opacity: 0;
}

.zoom-in.mfp-bg {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.hidden-wrapper {
  position: relative;
  padding: 2rem;
  margin: 1.2rem auto;
  background-color: #fff;
  width: 96%;
  max-width: 1024px;
}

.hidden-wrapper--lg {
  max-width: 1280px;
}

.hidden-wrapper--md {
  max-width: 768px;
}

.hidden-wrapper--sm {
  max-width: 480px;
}

.hidden-wrapper--strip {
  background: none;
  padding: 0;
}

.hidden-wrapper--no-gap {
  padding: 0;
}

.hidden-wrapper ._show-in--hidden-wrapper {
  display: block;
}

.sitemap {
  max-width: 768px;
  padding: 1rem;
  background-color: #f6f7f9;
  position: relative;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 768px) {
  .sitemap {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.sitemap ul {
  list-style: none;
  padding: 0 0 0 2em;
  margin: 0;
}

.sitemap li {
  list-style: none;
  line-height: 1.4em;
}

.sitemap a {
  padding: .2em 1em .2em .5em;
  display: block;
  color: #00e;
}

.sitemap a:visited {
  color: #0e00ad;
}

.sitemap a:hover {
  color: #f34;
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap a:active {
  color: #000;
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap > ul {
  padding: 0;
  margin: 1em 0;
  position: relative;
  overflow: hidden;
}

.sitemap > ul > li > ul {
  padding-left: 0;
}

.sitemap > ul ul {
  list-style: none;
  padding: 0 0 0 2em;
  margin: 0;
}

.sitemap > ul ul li {
  position: relative;
  margin: .1em 0;
}

.sitemap > ul ul li:hover {
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap > ul ul li a {
  position: relative;
  padding-left: 2.5em;
}

.sitemap > ul ul li a:before, .sitemap > ul ul li a:after {
  content: '';
  position: absolute;
  top: .2em;
  width: 1600px;
  height: 1.4em;
  text-align: right;
  opacity: .3;
  background: transparent repeat-x center right;
}

.sitemap > ul ul li a:before {
  left: 0;
  width: 2em;
  background-image: url(pic/sitemap-start.svg);
}

.sitemap > ul ul li a:after {
  right: 100%;
  background-image: url(pic/sitemap-middle.svg);
}

.sitemap > ul ul li:last-child > a:only-child:before {
  background-image: url(pic/sitemap-end.svg);
}

#fPopUp {
  max-width: 80% !important;
  min-width: 280px !important;
}

@media only screen and (min-width: 768px) {
  #fPopUp {
    max-width: 50% !important;
    min-width: 400px !important;
  }
}

.text {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
}

/* ---------- SVG Icons ---------- */
.svgi {
  display: inline-block;
  vertical-align: middle;
  transition: fill .3s ease;
}

.svgi--caret-down {
  width: 10px;
  height: 6px;
  fill: #fff;
}

.social__list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social__item {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
}

.social__item svg {
  display: block;
  max-width: 25px;
  max-height: 25px;
  margin: 0 auto;
  fill: #b6b6b6;
  transition: fill .3s ease;
}

.social__item--skype:hover svg {
  fill: #00aff0;
}

.social__item--fb:hover svg {
  fill: #3b5998;
}

.social__item--in:hover svg {
  fill: #007ab5;
}

.social__item--tw:hover svg {
  fill: #64a8f2;
}

.social__item--ins:hover svg {
  fill: #325c86;
}

.slick-prev, .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: block;
  width: 44px;
  height: 44px;
  background-color: transparent;
  border-radius: 50%;
  font-size: 0;
  transition: transform .3s ease;
}

.slick-prev:before, .slick-prev:after, .slick-next:before, .slick-next:after {
  content: '';
  position: absolute;
  top: 50%;
  display: block;
  width: 50%;
  height: 50%;
}

.slick-prev:active, .slick-next:active {
  transform: translateY(calc(-50% + 7px));
}

.slick-prev {
  left: 0;
  border: 2px solid #36b789;
}

.slick-prev:before {
  left: calc(50% + 5px);
  border-width: 2px;
  border-style: solid;
  border-color: transparent transparent #01b6ef #01b6ef;
  transform: translate(-50%, -50%) rotate(45deg);
}

.slick-prev.slick-disabled {
  transform: translateX(-100px);
}

.slick-next {
  right: 0;
  border: 2px solid #01b6ef;
}

.slick-next:after {
  left: calc(50% - 5px);
  border-width: 2px;
  border-style: solid;
  border-color: transparent #36b789 #36b789 transparent;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.slick-next.slick-disabled {
  transform: translateX(100px);
}

.question-wrap {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
  order: 1;
  margin-top: 10px;
  text-align: right;
}

@media only screen and (max-width: 559px) {
  .question-wrap {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1560px) {
  .question-wrap {
    width: initial;
    max-width: initial;
    flex-basis: initial;
    order: initial;
    margin-top: initial;
    text-align: initial;
  }
}

.products-types {
  margin-top: 6.25rem;
}

.products-types__item {
  text-decoration: none;
  overflow: hidden;
}

.products-types__item [data-hover-text]:after {
  width: 100%;
  opacity: 0;
  transform: scale(2, 4);
}

.products-types__item:hover {
  text-decoration: none;
}

.products-types__item:hover [data-hover-text] {
  color: #43b86f !important;
}

.products-types__item:hover [data-hover-text]:after {
  opacity: 1;
  transform: scale(1);
}

.navbar__item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 13px;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .3s ease;
  letter-spacing: .5px;
}

.navbar__item:hover {
  text-decoration: none;
}

.navbar__item.is-active {
  pointer-events: none;
}

.navbar__item.is-active[data-hover-text] {
  color: #353535 !important;
}

.navbar__item.is-active[data-hover-text]:after {
  width: 100%;
}

.mob-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 50vw;
  min-width: 320px;
  max-height: 100vh;
  padding: 0 0.9375rem;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transform: translateX(100%);
  transition: .3s ease;
  box-shadow: 0 0 3px 2px rgba(53, 53, 53, 0.3);
}

.menu-is-open .mob-menu {
  opacity: 1;
  transform: translateX(0);
}

.mob-menu ._mob-menu-breakpoint {
  display: block;
}

.mob-menu .burger {
  margin: 20px 0 7px auto;
}

@media only screen and (min-width: 560px) {
  .mob-menu ._mob-menu-breakpoint {
    display: none;
  }
}

.mob-menu__wrap {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.mob-menu__row + .mob-menu__row {
  margin-top: 1.25rem;
}

.mob-menu__navbar {
  align-self: center;
  width: 100%;
  margin-bottom: auto;
}

.mob-menu__navbar-item {
  text-align: center;
}

.mob-menu__navbar-link {
  display: block;
  padding: 10px 0;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .3s ease;
  letter-spacing: .5px;
}

.mob-menu__navbar-link:hover {
  text-decoration: none;
}

.mob-menu__navbar-link.is-active {
  pointer-events: none;
}

.mob-menu__navbar-link.is-active[data-hover-text] {
  color: #353535 !important;
}

.mob-menu__navbar-link.is-active[data-hover-text]:after {
  width: 100%;
  top: 10px;
}

.mob-menu__question-button {
  width: 100%;
}

.mob-menu__langs {
  display: flex;
  justify-content: center;
}

.mob-menu__langs-item {
  width: 48%;
  margin: auto;
  border-width: 1px;
  border-style: solid;
  border-bottom: none;
  border-image-slice: 2;
}

.mob-menu__langs-item:nth-child(even) {
  border-image-source: linear-gradient(to right, #00b5ef 30%, #43b86f);
}

.mob-menu__langs-item:nth-child(odd) {
  border-image-source: linear-gradient(to right, #43b86f 30%, #00b5ef);
}

.mob-menu__langs-link {
  position: relative;
  display: block;
  padding: 8px 0;
  color: #fff;
  text-align: center;
  text-decoration: none;
}

.mob-menu__langs-link:hover {
  text-decoration: none;
}

.mob-menu__langs-link.is-active {
  pointer-events: none;
}

.mob-menu__langs-link.is-active:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: linear-gradient(to right bottom, rgba(67, 184, 111, 0.3) 30%, rgba(0, 181, 239, 0.3));
  opacity: 1;
  transition: opacity .3s ease;
  overflow: hidden;
}

.mob-menu__bg-logo {
  position: absolute;
  top: calc(50% - 20px);
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  z-index: 0;
  opacity: .7;
}

@media only screen and (max-height: 500px) {
  .mob-menu__bg-logo {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: .4;
  }
}

.burger {
  width: 30px;
  height: 26px;
  cursor: pointer;
}

.burger span {
  display: block;
  position: relative;
  width: 30px;
  height: 2px;
  background-color: #fff;
  top: 12px;
  -webkit-transition: background .25s ease;
  transition: background .25s ease;
}

.burger span:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  top: -10px;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: background .25s ease,top .25s ease .25s,-webkit-transform .25s ease;
  transition: background .25s ease,top .25s ease .25s,-webkit-transform .25s ease;
  transition: background .25s ease,transform .25s ease,top .25s ease .25s;
  transition: background .25s ease,transform .25s ease,top .25s ease .25s,-webkit-transform .25s ease;
}

.burger span:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  bottom: -10px;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: background .25s ease,bottom .25s ease .25s,-webkit-transform .25s ease;
  transition: background .25s ease,bottom .25s ease .25s,-webkit-transform .25s ease;
  transition: background .25s ease,transform .25s ease,bottom .25s ease .25s;
  transition: background .25s ease,transform .25s ease,bottom .25s ease .25s,-webkit-transform .25s ease;
}

.burger.is-active span {
  background: transparent !important;
}

.burger.is-active span:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0;
  -webkit-transition: top .25s ease,-webkit-transform .25s ease .25s;
  transition: top .25s ease,-webkit-transform .25s ease .25s;
  transition: top .25s ease,transform .25s ease .25s;
  transition: top .25s ease,transform .25s ease .25s,-webkit-transform .25s ease .25s;
}

.burger.is-active span:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  bottom: 0;
  -webkit-transition: bottom .25s ease,-webkit-transform .25s ease .25s;
  transition: bottom .25s ease,-webkit-transform .25s ease .25s;
  transition: bottom .25s ease,transform .25s ease .25s;
  transition: bottom .25s ease,transform .25s ease .25s,-webkit-transform .25s ease .25s;
}

.burger-overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  width: auto;
  height: 100vh;
  cursor: default;
  margin-right: 0;
}

.menu-is-open .burger-overlay {
  display: block;
}

.media__icon {
  flex-shrink: 0;
  margin-right: 15px;
}

.media__content {
  flex-grow: 1;
}

.media--obj {
  display: flex;
  align-items: center;
}

.management__item {
  text-align: center;
}

.management__item video::-webkit-media-controls-enclosure {
  display: none !important;
}

.management__trigger-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20px);
  z-index: 1;
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  background-image: url("../css/pic/iphone-play.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
}

.no-touchevents .management__trigger-play {
  display: none !important;
  background-image: none !important;
}

.iphone .management__trigger-play {
  opacity: 0;
}

.logo {
  transform-origin: center;
  transition: transform .3s ease;
}

.logo__slogan {
  display: none;
  vertical-align: middle;
  margin-top: -4px;
  color: #353535;
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
}

@media only screen and (min-width: 1280px) {
  .logo__slogan {
    display: inline-block;
  }
}

.logo--simple {
  transform: translateY(-50%) scale(0);
}

@media only screen and (min-width: 1024px) {
  .logo--simple {
    transform: translate(-50%, -50%) scale(0);
  }
}

.logo--colored {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%) scale(1);
}

@media only screen and (min-width: 1024px) {
  .logo--colored {
    transform: translate(-50%, -50%) scale(1);
  }
}

.logo--colored .logo__wrap {
  width: 50px;
  height: 50px;
  margin: 0 5px;
}

@media only screen and (min-width: 560px) {
  .logo--colored .logo__wrap {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .logo--colored .logo__wrap {
    width: 100px;
    height: 100px;
  }
}

.link {
  display: inline-block;
  vertical-align: middle;
  transition: color .3s ease;
}

.link--phone {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 1px;
}

.link--phone:hover {
  text-decoration: none;
}

.langs__link {
  display: inline-block;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  transition: color .3s ease;
}

.langs__link:hover {
  text-decoration: none;
}

.infographic__item {
  text-align: center;
}

.infographic__item:first-child .media-card__title:after {
  content: '+';
  background-image: linear-gradient(to top, #d3d3d3 40%, #fff 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.ie .infographic__item:first-child .media-card__title:after {
  background-image: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
}

.key-dates__list {
  display: block;
  width: 100%;
  max-width: 1000px;
  margin: 3.125rem auto;
}

.key-dates__item {
  display: block;
  min-height: 250px;
  padding: 0.9375rem 1rem 1.5625rem;
  background-color: #fff;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(30%);
  opacity: 0;
  transition: transform .6s ease .2s, opacity .6s ease .2s;
}

.key-dates__item.animate-me {
  transform: translateY(0);
  opacity: 1;
}

.key-dates__item + .key-dates__item {
  margin-top: 1.25rem;
}

@media only screen and (min-width: 640px) {
  .key-dates__item {
    padding: 1.875rem 3.125rem;
  }
}

.key-dates__date {
  display: block;
  color: #353535;
  font-size: 3.125rem;
  font-weight: 700;
}

.key-dates__msg {
  display: block;
  width: 100%;
  margin: 0 auto;
  color: #353535;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
}

.key-dates__msg--top {
  max-width: 750px;
}

@media only screen and (min-width: 640px) {
  .key-dates__msg--top {
    font-size: 1rem;
  }
}

.key-dates__msg--bottom {
  max-width: 520px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media only screen and (min-width: 480px) {
  .key-dates__msg--bottom {
    font-size: 1.875rem;
  }
}

.founded {
  font-size: 5rem;
  position: relative;
  max-width: 4em;
  z-index: 2;
}

.founded svg {
  fill: #fff;
  opacity: .2;
  overflow: visible;
  position: absolute;
  top: -1em;
  left: -2em;
  right: -2em;
  bottom: -1em;
  margin: auto;
  height: 4em;
  width: 6em;
}

.moz .founded svg {
  opacity: .4;
}

.founded__date {
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  text-align: center;
  text-shadow: 1px 1px 0 #353535;
}

.founded__text {
  color: #fff;
  font-size: .25em;
  font-weight: 100;
  text-align: center;
  text-shadow: 1px 1px 0 #353535;
}

@media only screen and (min-width: 1920px) {
  .founded {
    font-size: 10rem;
  }
}

@media only screen and (min-width: 768px) {
  .form__group--textarea {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
  }
  .ios .form__group--textarea,
  .safari .form__group--textarea,
  .mac .form__group--textarea,
  .maclike .form__group--textarea,
  .ipad .form__group--textarea,
  .iphone .form__group--textarea {
    display: block;
    height: 290px;
  }
}

@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .ios .form__group--textarea,
  .safari .form__group--textarea,
  .mac .form__group--textarea,
  .maclike .form__group--textarea,
  .ipad .form__group--textarea,
  .iphone .form__group--textarea {
    height: 276px;
  }
}

.form__group--submit {
  margin-top: 2.5rem;
  text-align: center;
}

.form__group--submit.js-init {
  opacity: 0;
  transition: opacity .2s ease;
}

.mfp-content .form__group--submit.js-init, .form__group--submit.js-init.animated {
  opacity: 1;
}

.form__gvertical .form__group + .form__group {
  margin-top: 1.875rem;
}

@media only screen and (min-width: 768px) {
  .form__gvertical .form__group + .form__group {
    margin-top: 1.25rem;
  }
}

.form__hint {
  display: block;
  margin-top: 0.625rem;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 300;
  opacity: .37;
  letter-spacing: .5px;
}

.form--contacts {
  display: block;
  width: 100%;
  max-width: 1032px;
  margin: 0 auto;
}

.dropdown {
  position: relative;
}

.dropdown__trigger {
  cursor: pointer;
}

.dropdown__content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: visibility .2s, opacity .2s;
  z-index: 1;
}

.dropdown--up .dropdown__content {
  bottom: 100%;
}

.dropdown--down .dropdown__content {
  top: 100%;
}

.dropdown.is-open .dropdown__content,
.dropdown:not([data-init="dropdown"]):hover .dropdown__content {
  visibility: visible;
  opacity: 1;
}

._color-developer {
  color: #fc171f;
}

.developer {
  display: flex;
  align-items: center;
  text-decoration: none;
  max-width: 310px;
  margin: 0 auto;
}

@media only screen and (min-width: 1024px) {
  .developer {
    max-width: initial;
    margin: initial;
  }
}

.developer__icon {
  display: block;
  width: 46px;
  height: 18px;
  flex-shrink: 0;
}

.developer__icon svg {
  width: 100%;
  height: 100%;
  fill: #fc171f;
}

.developer__content {
  display: block;
  flex-grow: 1;
  margin-left: 10px;
  color: #90898f;
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.4;
  transition: color .3s ease;
}

@media only screen and (min-width: 640px) {
  .developer__content {
    font-size: 0.8125rem;
  }
}

.developer:hover {
  text-decoration: none;
}

.developer:hover .developer__icon {
  transform-origin: center;
  transform: scaleX(-1);
  transition: transform .3s ease;
}

.developer:hover .developer__content {
  color: #fc171f;
}

.copyright {
  color: #90898f;
  font-size: 0.8125rem;
  font-weight: 300;
  line-height: 1.4;
}

.control {
  position: relative;
}

.control.has-error .control__input,
.control.has-error .control__textarea {
  color: #fff;
  border-color: #fc171f;
  background-color: rgba(252, 23, 31, 0.5);
}

.control.has-error .control__input::placeholder,
.control.has-error .control__textarea::placeholder {
  color: #fff;
  color: rgba(255, 255, 255, 0.5);
}

.control.has-error label.has-error {
  position: absolute;
  bottom: 100%;
  left: 8px;
  right: 8px;
  z-index: auto;
  transform: translateY(50%) scaleY(1);
  display: block;
  padding: 0.375rem 0.4375rem 0.3125rem;
  border-radius: 4px;
  background-color: #fc171f;
  color: #fff;
  text-shadow: 1px 1px 0 #353535;
  font-size: 0.84375rem;
  font-weight: 100;
  letter-spacing: .5px;
  cursor: pointer;
  opacity: 1;
  transform-origin: center;
  transition: opacity .3s ease, transform .3s ease;
}

@media only screen and (min-width: 1024px) {
  .control.has-error label.has-error {
    font-size: 0.75rem;
  }
}

.control.mask-success .mask-country-for {
  position: absolute;
  top: 100%;
  left: 8px;
  right: 8px;
  z-index: auto;
  transform: translateY(-50%) scaleY(1);
  display: block;
  padding: 0.375rem 0.4375rem 0.3125rem;
  border-radius: 4px;
  background-color: #2bb8ac;
  color: #fff;
  text-shadow: 0 0 10px #353535;
  font-size: 0.84375rem;
  font-weight: 100;
  letter-spacing: 1px;
  cursor: pointer;
  opacity: 1;
  transform-origin: center;
  transition: opacity .3s ease, transform .3s ease;
}

@media only screen and (min-width: 1024px) {
  .control.mask-success .mask-country-for {
    font-size: 0.8125rem;
  }
}

.control__input, .control__textarea {
  display: block;
  width: 100%;
  padding: 0.875rem;
  border: 2px solid transparent;
  border-image-slice: 2;
  border-radius: 7px;
  background-color: #f3f3f3;
  color: #353535;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: .5px;
  transition: .3s ease;
}

.control__input::placeholder, .control__textarea::placeholder {
  display: block;
  color: #353535;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: .5px;
}

.control__input:focus, .control__textarea:focus {
  background-color: rgba(51, 51, 51, 0.5) !important;
  color: #fff !important;
  border-color: #43b86f !important;
  border-image-source: linear-gradient(to right, #43b86f 30%, #00b5ef) !important;
}

.control__input:focus::placeholder, .control__textarea:focus::placeholder {
  color: #fff !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.control__input:focus + label.has-error, .control__textarea:focus + label.has-error {
  transform: translateY(50%) scaleY(0);
  opacity: 0;
}

.control__textarea {
  resize: vertical;
}

.popup--question .control__textarea,
.contacts .control__textarea {
  height: 250px;
}

@media only screen and (min-width: 768px) {
  .popup--question .control__textarea,
  .contacts .control__textarea {
    min-height: initial;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
  }
  .ios .popup--question .control__textarea,
  .safari .popup--question .control__textarea,
  .mac .popup--question .control__textarea,
  .maclike .popup--question .control__textarea,
  .ipad .popup--question .control__textarea,
  .iphone .popup--question .control__textarea, .ios
  .contacts .control__textarea,
  .safari
  .contacts .control__textarea,
  .mac
  .contacts .control__textarea,
  .maclike
  .contacts .control__textarea,
  .ipad
  .contacts .control__textarea,
  .iphone
  .contacts .control__textarea {
    display: block;
    height: 290px;
  }
}

@media only screen and (min-width: 768px) and (min-width: 1280px) {
  .ios .popup--question .control__textarea,
  .safari .popup--question .control__textarea,
  .mac .popup--question .control__textarea,
  .maclike .popup--question .control__textarea,
  .ipad .popup--question .control__textarea,
  .iphone .popup--question .control__textarea, .ios
  .contacts .control__textarea,
  .safari
  .contacts .control__textarea,
  .mac
  .contacts .control__textarea,
  .maclike
  .contacts .control__textarea,
  .ipad
  .contacts .control__textarea,
  .iphone
  .contacts .control__textarea {
    height: 276px;
  }
}

@media only screen and (min-width: 768px) {
  .control--textarea {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
  }
}

.contacts__types {
  display: block;
  width: 100%;
  max-width: 1032px;
  margin: 0 auto 2.5rem;
  text-align: center;
}

@media only screen and (min-width: 640px) {
  .contacts__types {
    text-align: left;
  }
}

.contacts__types .text {
  display: block;
  font-size: 1.25rem;
  font-weight: 100;
}

@media only screen and (min-width: 640px) {
  .contacts__types .text {
    margin-bottom: 1.25rem;
  }
}

@media only screen and (min-width: 940px) {
  .contacts__types .text {
    font-size: 1.625rem;
  }
}

.contacts__types .link {
  display: inline-block;
  color: #fff;
  font-size: 1.625rem;
  font-weight: 700;
  text-decoration: none;
}

.contacts__types .link:hover {
  text-decoration: none;
}

@media only screen and (min-width: 940px) {
  .contacts__types .link {
    font-size: 2.5rem;
  }
}

.contacts__types .js-init {
  opacity: 0;
  transition: opacity .2s ease;
}

.contacts__types .js-init.animated {
  opacity: 1;
}

.contacts__msg {
  display: block;
  width: 100%;
  max-width: 630px;
  margin: 0 auto;
  margin-bottom: 2.5rem;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 100;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .contacts__msg {
    font-size: 1.625rem;
  }
}

.contacts__table {
  margin-top: 2.5rem;
}

.mouse {
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: none;
  width: 26px;
  height: 44px;
  border: 1px solid #fff;
  border-radius: 20px;
  cursor: pointer;
}

@media only screen and (min-height: 840px) and (min-width: 1025px) {
  .mouse {
    display: block;
  }
}

.mouse > span {
  content: '';
  position: absolute;
  top: 10px;
  left: calc(50% - 2px);
  z-index: 1;
  display: block;
  width: 5px;
  height: 5px;
  background-color: #fff;
  border-radius: 50%;
  animation: translate 2s infinite;
}

.button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 9px 24px 7px;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  outline: none;
  z-index: 1;
  transition: .3s ease;
}

.button--def {
  min-width: 200px;
  background-color: transparent;
  color: #fff;
  border-style: solid;
  border-width: 2px;
  border-image: url("../css/pic/svgi/border.svg") 2 fill stretch;
  border-radius: 4px;
  overflow: hidden;
  transition: all .3s ease;
}

.button--def:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 4px;
  background-image: linear-gradient(to right bottom, rgba(67, 184, 111, 0.3) 30%, rgba(0, 181, 239, 0.3));
  opacity: 0;
  transition: opacity .3s ease;
  overflow: hidden;
}

.ie .button--def,
.edge .button--def {
  border-radius: 0;
  border-image-slice: 2;
  border-image-source: linear-gradient(to right, #43b86f 30%, #00b5ef);
}

.ie .button--def:after,
.edge .button--def:after {
  border-radius: 0;
}

.button--def:hover:after {
  opacity: 1;
}

.button:active {
  transform: translateY(6px);
}

.advantages__list {
  display: block;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.advantages__item {
  display: block;
  min-height: 250px;
  padding: 1.875rem 3.125rem;
  background-color: #253850;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(30%);
  opacity: 0;
  transition: transform .6s ease .2s, opacity .6s ease .2s;
}

.advantages__item.animate-me {
  transform: translateY(0);
  opacity: 1;
}

.advantages__item + .advantages__item {
  margin-top: 1.25rem;
}

.about-vid {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 280px;
  min-height: 100px;
  background-color: #000;
  transform: translateY(100%);
  transition: transform .3s ease;
}

.about-vid__title {
  display: block;
  margin: 1.5625rem 0;
  padding: 0 10px;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: .5px;
}

.about-vid__show-video {
  display: block;
  max-width: 48px;
  height: auto;
  margin: 0 auto;
  border: none;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  transition: opacity .3s ease, transform .3s ease;
  animation: blink 3s infinite;
}

.about-vid__show-video img {
  display: block;
  max-width: 100%;
  height: auto;
}

.about-vid__show-video:hover {
  animation-play-state: paused;
  transform: scale(1.1);
  text-decoration: none;
}

.about-vid__seen-already {
  display: block;
  margin: 1.5625rem 0;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: .5px;
  opacity: .5;
  cursor: pointer;
  transition: opacity .3s ease;
}

.about-vid__seen-already > span {
  display: inline-block;
  border-bottom: 1px solid #fff;
}

.about-vid__seen-already:hover {
  opacity: .9;
}

.about-vid__close {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  display: block;
  width: 10px;
  height: 10px;
  padding: 0;
  border: none;
  background-color: transparent;
  fill: #fff;
  cursor: pointer;
}

.about-vid__close svg {
  display: block;
  width: 10px;
  height: 10px;
}

.about-vid.is-visible {
  transform: translateY(0) !important;
}

.media-card--horizontal {
  display: flex;
}

.media-card--products-types {
  text-align: center;
}

.media-card--products-types .media-card__aside img {
  margin: 0 auto;
  transition: transform .3s ease;
  backface-visibility: hidden;
  max-height: 135px;
}

.media-card--products-types .media-card__content {
  margin-top: 1.625rem;
}

.media-card--products-types .media-card__title {
  display: inline-block;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
}

.media-card--products-types .media-card__title:hover {
  text-decoration: none;
}

.media-card--products-types:hover .media-card__aside img {
  transform: scale(0.9);
}

.media-card--advantages .media-card__aside {
  position: relative;
  width: 220px;
  min-width: 130px;
  min-height: 180px;
}

@media only screen and (min-width: 768px) {
  .media-card--advantages .media-card__aside {
    flex-shrink: 0;
  }
}

.media-card--advantages .media-card__aside img,
.media-card--advantages .media-card__aside svg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}

.media-card--advantages .media-card__aside img {
  transform: translate(-50%, -50%);
}

.media-card--advantages .media-card__aside svg {
  display: inline-block;
  max-width: 170px;
  fill: #4c5b6e;
  transform: translate(-50%, -50%) scale(1.2);
  overflow: visible;
}

.moz .media-card--advantages .media-card__aside svg {
  transform: scale(1.15);
  top: calc(50% - 95px);
  left: calc(50% - 100px);
}

.media-card--advantages .media-card__content {
  padding-top: 0.9375rem;
  text-align: center;
}

@media only screen and (min-width: 640px) {
  .media-card--advantages .media-card__content {
    margin-left: 3.125rem;
    padding-left: 2.5rem;
    border-left: 1px solid #4c5b6e;
    text-align: left;
  }
}

@media only screen and (min-width: 768px) {
  .media-card--advantages .media-card__content {
    flex-grow: 1;
    padding-left: 5rem;
  }
}

.media-card--advantages .media-card__title {
  display: block;
  margin-bottom: 1.5625rem;
  color: #a2a7b0;
  font-size: 1.625rem;
  font-weight: 500;
}

@media only screen and (min-width: 1024px) {
  .media-card--advantages .media-card__title {
    font-size: 1.875rem;
  }
}

.media-card--advantages .media-card__desc {
  display: block;
  width: 100%;
  max-width: 505px;
  color: #626c7c;
  font-size: 1.25rem;
  font-weight: 100;
  line-height: 1.33;
}

@media only screen and (min-width: 1024px) {
  .media-card--advantages .media-card__desc {
    font-size: 1.5rem;
  }
}

.media-card--advantages:nth-child(even) {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 639px) {
  .media-card--advantages:nth-child(even) {
    flex-direction: column;
  }
}

.media-card--advantages:nth-child(even) .media-card__content {
  margin-left: initial;
  padding-left: initial;
  border-left: initial;
}

@media only screen and (min-width: 640px) {
  .media-card--advantages:nth-child(even) .media-card__content {
    margin-right: 3.125rem;
    padding-right: 5rem;
    border-right: 1px solid #4c5b6e;
  }
}

@media only screen and (max-width: 639px) {
  .media-card--advantages {
    flex-direction: column;
    align-items: center;
  }
}

.media-card--infographic .media-card__aside {
  margin-bottom: 1.25rem;
}

.media-card--infographic .media-card__aside img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.media-card--infographic .media-card__title {
  display: block;
  margin-bottom: 0.625rem;
  background-image: linear-gradient(to top, #d3d3d3 40%, #fff 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #fff;
  font-size: 3.75rem;
  font-weight: 700;
}

.ie .media-card--infographic .media-card__title {
  background-image: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
}

.media-card--infographic .media-card__desc {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 100;
}

.media-card--management .media-card__aside {
  position: relative;
  display: block;
  width: 100%;
  max-width: 90%;
  height: 0;
  padding-top: 128.57%;
  margin: 0 auto 20px;
  background-color: #fff;
}

.media-card--management .media-card__aside video {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 0;
  transform: translateX(-50%);
  max-width: 100%;
  max-height: 100%;
}

.media-card--management .media-card__aside video.is-active {
  z-index: 1;
}

.media-card--management .media-card__title {
  display: block;
  margin-bottom: 0.5rem;
  color: #353535;
  font-size: 1.375rem;
  font-weight: 700;
}

@media only screen and (min-width: 1024px) {
  .media-card--management .media-card__title {
    font-size: 1.625rem;
  }
}

.media-card--management .media-card__desc {
  display: block;
  width: 100%;
  max-width: 350px;
  margin: 0 auto 0.5rem;
  color: #353535;
  font-size: 1rem;
  font-weight: 100;
}

@media only screen and (min-width: 1024px) {
  .media-card--management .media-card__desc {
    font-size: 1.25rem;
  }
}

.media-card--key-dates {
  align-items: center;
}

.media-card--key-dates .media-card__aside {
  position: relative;
  width: 220px;
  min-width: 130px;
  min-height: 250px;
}

@media only screen and (min-width: 768px) {
  .media-card--key-dates .media-card__aside {
    flex-shrink: 0;
  }
}

.media-card--key-dates .media-card__aside .key-dates__date,
.media-card--key-dates .media-card__aside svg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}

.media-card--key-dates .media-card__aside .key-dates__date {
  top: calc(50% + 5px);
  z-index: 2;
  transform: translate(-50%, -50%);
}

.media-card--key-dates .media-card__aside svg {
  display: inline-block;
  max-width: 170px;
  fill: #ebebea;
  transform: translate(-50%, -50%) scale(1.2);
  overflow: visible;
}

.moz .media-card--key-dates .media-card__aside svg {
  transform: scale(1.15);
  top: calc(50% - 95px);
  left: calc(50% - 100px);
}

.media-card--key-dates .media-card__content {
  padding-top: 0.9375rem;
  text-align: center;
}

@media only screen and (min-width: 640px) {
  .media-card--key-dates .media-card__content {
    margin-left: 3.125rem;
    text-align: left;
  }
}

@media only screen and (min-width: 768px) {
  .media-card--key-dates .media-card__content {
    flex-grow: 1;
  }
}

.media-card--key-dates .media-card__desc {
  display: block;
  width: 100%;
  color: #353535;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.33;
}

@media only screen and (min-width: 1024px) {
  .media-card--key-dates .media-card__desc {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 639px) {
  .media-card--key-dates {
    flex-direction: column;
    align-items: center;
  }
}

.table--contacts .table__row {
  padding: 2.8125rem 0 2.625rem;
  background-color: #f3f3f3;
  border-bottom: 1px solid #e5e5e5;
}

@media only screen and (min-width: 1024px) {
  .table--contacts .table__row > .view-size > .grid {
    flex-wrap: nowrap;
  }
}

.table--contacts .table__cell .link {
  color: #353535;
  font-size: 1rem;
  font-weight: 300;
  text-decoration: none;
}

.table--contacts .table__cell .link--phone {
  display: block;
  white-space: nowrap;
}

.table--contacts .table__cell .link--phone + .link--phone {
  margin-top: 6px;
}

@media only screen and (min-width: 1024px) {
  .table--contacts .table__cell .link--phone + .link--phone {
    margin-top: 0;
  }
}

.table--contacts .table__cell .link:hover:not(.link--map) {
  text-decoration: underline;
}

.table--contacts .table__cell .media--phone {
  white-space: nowrap;
}

.table--contacts .table__cell--nth-1 {
  width: 50%;
  text-align: right;
  color: #353535;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

@media only screen and (min-width: 1024px) {
  .table--contacts .table__cell--nth-1 {
    width: calc(31% / 2);
    text-align: initial;
  }
}

.table--contacts .table__cell--nth-2 {
  width: 50%;
  color: #353535;
  font-size: 1rem;
  font-weight: 300;
}

@media only screen and (min-width: 1024px) {
  .table--contacts .table__cell--nth-2 {
    width: calc(31% / 2);
    text-align: center;
  }
}

.table--contacts .table__cell--nth-3 {
  width: 100%;
}

.table--contacts .table__cell--nth-3 .media {
  max-width: 300px;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .table--contacts .table__cell--nth-3 {
    width: 33%;
  }
}

@media only screen and (min-width: 1024px) {
  .table--contacts .table__cell--nth-3 {
    width: calc(69% / 3);
  }
}

.table--contacts .table__cell--nth-4 {
  width: 100%;
}

.table--contacts .table__cell--nth-4 .media {
  max-width: 300px;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .table--contacts .table__cell--nth-4 {
    width: 33%;
  }
}

@media only screen and (min-width: 1024px) {
  .table--contacts .table__cell--nth-4 {
    width: calc(69% / 3);
  }
}

.table--contacts .table__cell--nth-5 {
  width: 100%;
}

.table--contacts .table__cell--nth-5 .media {
  max-width: 300px;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .table--contacts .table__cell--nth-5 {
    width: 33%;
  }
}

@media only screen and (min-width: 1024px) {
  .table--contacts .table__cell--nth-5 {
    width: calc(69% / 3);
  }
}

@keyframes translate {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  40% {
    opacity: .5;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes burger {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(72deg);
    transform: rotate(72deg);
  }
  40% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
  60% {
    -webkit-transform: rotate(216deg);
    transform: rotate(216deg);
  }
  80% {
    -webkit-transform: rotate(288deg);
    transform: rotate(288deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

[data-hover-text] {
  position: relative;
  z-index: 1;
  transition: color .3s ease;
}

[data-hover-text]:after {
  content: attr(data-hover-text);
  display: block;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  color: #01b5f0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  width: 0;
  transition: width .3s ease, opacity .3s ease, transform .3s ease;
}

@supports ((background-clip: text) or (-webkit-background-clip: text)) and ((text-fill-color: transparent) or (-webkit-text-fill-color: transparent)) {
  [data-hover-text]:after {
    background-image: linear-gradient(to right, #43b86f 30%, #00b5ef);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
  }
}

[data-hover-text]:hover {
  color: #353535 !important;
}

[data-hover-text]:hover:after {
  width: 100%;
}

.mfp-bg {
  background: #0a1f34;
  opacity: .9 !important;
}

.mfp-close {
  font-size: 0 !important;
  background-image: url("../css/pic/svgi/close-grad.svg") !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: 50% 50% !important;
  cursor: pointer !important;
}

.mfp-iframe-holder .mfp-content {
  position: relative;
}

.mfp-iframe-holder .mfp-content .mfp-close {
  width: 44px;
  height: 44px;
  top: -34px !important;
  right: 0 !important;
}

@media only screen and (min-width: 1024px) {
  .mfp-iframe-holder .mfp-content .mfp-close {
    top: -22px !important;
    right: -22px !important;
  }
}

.popup {
  position: relative;
}

.popup__heading {
  margin-bottom: 1.875rem;
}

.popup__title {
  display: block;
  margin-bottom: 1.25rem;
  color: #fff;
  font-size: 1.75rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: .5px;
}

@media only screen and (min-width: 640px) {
  .popup__title {
    font-size: 3.75rem;
  }
}

.popup__subtitle {
  display: block;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  color: #fff;
  font-size: 1rem;
  font-weight: 100;
  text-align: center;
}

@media only screen and (min-width: 640px) {
  .popup__subtitle {
    font-size: 1.625rem;
  }
}

.popup--question {
  background-color: transparent;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  animation-duration: .75s;
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

.zoomInSlow {
  animation-name: zoomInSlow;
  animation-duration: 4s;
}

@keyframes zoomInSlow {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipInX;
}

.has-tooltip {
  position: relative;
}

.has-tooltip:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip {
  position: absolute;
  z-index: 10;
  display: inline-block;
  width: 100%;
  min-width: 100px;
  padding: 5px 5px;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity .4s ease;
}

.tooltip:after {
  content: "";
  position: absolute;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: inherit;
  border-left-color: transparent;
}

.tooltip--top {
  left: 50%;
  bottom: calc(100% + 2px);
  transform: translateX(-50%);
}

.tooltip--top:after {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-top-color: inherit;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.tooltip--left {
  top: 50%;
  left: calc(-100% - 10px);
  transform: translateY(-50%);
}

.tooltip--left:after {
  top: 50%;
  left: calc(100% - -5px);
  transform: translateY(-50%);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: inherit;
}

.tooltip--right {
  top: 50%;
  right: calc(-100% - 10px);
  transform: translateY(-50%);
}

.tooltip--right:after {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-top-color: transparent;
  border-right-color: inherit;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.tooltip--bottom {
  top: calc(100% + 2px);
  left: 50%;
  transform: translateX(-50%);
}

.tooltip--bottom:after {
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: inherit;
  border-left-color: transparent;
}

.tooltip--lg {
  min-width: 160px;
  padding: 15px 20px;
}

.tooltip--md {
  min-width: 140px;
  padding: 10px 15px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.grid .gbox {
  height: 100%;
  min-height: 100%;
}

.grid--1 > .gcell {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

.grid--2 > .gcell {
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}

.grid--3 > .gcell {
  width: 33.3333333%;
  max-width: 33.3333333%;
  flex-basis: 33.3333333%;
}

.grid--4 > .gcell {
  width: 25%;
  max-width: 25%;
  flex-basis: 25%;
}

.grid--5 > .gcell {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}

.grid--6 > .gcell {
  width: 16.6666667%;
  max-width: 16.6666667%;
  flex-basis: 16.6666667%;
}

.grid--7 > .gcell {
  width: 14.2857143%;
  max-width: 14.2857143%;
  flex-basis: 14.2857143%;
}

.grid--8 > .gcell {
  width: 12.5%;
  max-width: 12.5%;
  flex-basis: 12.5%;
}

.grid--9 > .gcell {
  width: 11.1111111%;
  max-width: 11.1111111%;
  flex-basis: 11.1111111%;
}

.grid--10 > .gcell {
  width: 10%;
  max-width: 10%;
  flex-basis: 10%;
}

.grid--11 > .gcell {
  width: 9.0909091%;
  max-width: 9.0909091%;
  flex-basis: 9.0909091%;
}

.grid--12 > .gcell {
  width: 8.3333333%;
  max-width: 8.3333333%;
  flex-basis: 8.3333333%;
}

.grid--space-sm {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.grid--space-sm > .gcell {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.grid--vspace-sm {
  margin-top: -2.5px;
  margin-bottom: -2.5px;
}

.grid--vspace-sm > .gcell {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}

.grid--space-md {
  margin-left: -5px;
  margin-right: -5px;
}

.grid--space-md > .gcell {
  padding-left: 5px;
  padding-right: 5px;
}

.grid--vspace-md {
  margin-top: -5px;
  margin-bottom: -5px;
}

.grid--vspace-md > .gcell {
  padding-top: 5px;
  padding-bottom: 5px;
}

.grid--space-def {
  margin-left: -10px;
  margin-right: -10px;
}

.grid--space-def > .gcell {
  padding-left: 10px;
  padding-right: 10px;
}

.grid--vspace-def {
  margin-top: -10px;
  margin-bottom: -10px;
}

.grid--vspace-def > .gcell {
  padding-top: 10px;
  padding-bottom: 10px;
}

.grid--space-lg {
  margin-left: -15px;
  margin-right: -15px;
}

.grid--space-lg > .gcell {
  padding-left: 15px;
  padding-right: 15px;
}

.grid--vspace-lg {
  margin-top: -15px;
  margin-bottom: -15px;
}

.grid--vspace-lg > .gcell {
  padding-top: 15px;
  padding-bottom: 15px;
}

.grid--space-xl {
  margin-left: -20px;
  margin-right: -20px;
}

.grid--space-xl > .gcell {
  padding-left: 20px;
  padding-right: 20px;
}

.grid--vspace-xl {
  margin-top: -20px;
  margin-bottom: -20px;
}

.grid--vspace-xl > .gcell {
  padding-top: 20px;
  padding-bottom: 20px;
}

.grid--space-mg {
  margin-left: -25px;
  margin-right: -25px;
}

.grid--space-mg > .gcell {
  padding-left: 25px;
  padding-right: 25px;
}

.grid--vspace-mg {
  margin-top: -25px;
  margin-bottom: -25px;
}

.grid--vspace-mg > .gcell {
  padding-top: 25px;
  padding-bottom: 25px;
}

.grid > .gcell {
  display: inline-block;
}

.grid > .gcell--1 {
  width: 8.3333333%;
  max-width: 8.3333333%;
  flex-basis: 8.3333333%;
}

.grid > .gcell--2 {
  width: 16.6666667%;
  max-width: 16.6666667%;
  flex-basis: 16.6666667%;
}

.grid > .gcell--3 {
  width: 25%;
  max-width: 25%;
  flex-basis: 25%;
}

.grid > .gcell--4 {
  width: 33.3333333%;
  max-width: 33.3333333%;
  flex-basis: 33.3333333%;
}

.grid > .gcell--5 {
  width: 41.6666667%;
  max-width: 41.6666667%;
  flex-basis: 41.6666667%;
}

.grid > .gcell--6 {
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}

.grid > .gcell--7 {
  width: 58.3333333%;
  max-width: 58.3333333%;
  flex-basis: 58.3333333%;
}

.grid > .gcell--8 {
  width: 66.6666667%;
  max-width: 66.6666667%;
  flex-basis: 66.6666667%;
}

.grid > .gcell--9 {
  width: 75%;
  max-width: 75%;
  flex-basis: 75%;
}

.grid > .gcell--10 {
  width: 83.3333333%;
  max-width: 83.3333333%;
  flex-basis: 83.3333333%;
}

.grid > .gcell--11 {
  width: 91.6666667%;
  max-width: 91.6666667%;
  flex-basis: 91.6666667%;
}

.grid > .gcell--12 {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

@media only screen and (min-width: 375px) {
  .grid--xs-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--xs-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--xs-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--xs-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--xs-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--xs-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--xs-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--xs-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--xs-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--xs-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--xs-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--xs-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--xs-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--xs-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--xs-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--xs-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--xs-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--xs-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--xs-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--xs-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--xs-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--xs-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--xs-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--xs-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--xs-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--xs-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 480px) {
  .grid--sm-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--sm-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--sm-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--sm-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--sm-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--sm-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--sm-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--sm-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--sm-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--sm-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--sm-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--sm-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--sm-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--sm-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--sm-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--sm-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--sm-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--sm-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--sm-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--sm-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--sm-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--sm-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--sm-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--sm-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--sm-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--sm-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 640px) {
  .grid--ms-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--ms-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--ms-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--ms-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--ms-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--ms-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--ms-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--ms-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--ms-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--ms-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--ms-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--ms-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--ms-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--ms-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--ms-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--ms-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--ms-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--ms-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--ms-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--ms-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--ms-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--ms-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--ms-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--ms-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--ms-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--ms-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 768px) {
  .grid--md-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--md-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--md-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--md-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--md-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--md-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--md-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--md-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--md-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--md-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--md-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--md-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--md-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--md-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--md-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--md-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--md-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--md-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--md-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--md-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--md-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--md-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--md-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--md-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--md-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--md-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .grid--def-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--def-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--def-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--def-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--def-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--def-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--def-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--def-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--def-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--def-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--def-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--def-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--def-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--def-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--def-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--def-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--def-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--def-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--def-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--def-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--def-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--def-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--def-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--def-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--def-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--def-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 1280px) {
  .grid--lg-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--lg-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--lg-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--lg-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--lg-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--lg-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--lg-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--lg-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--lg-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--lg-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--lg-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--lg-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--lg-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--lg-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--lg-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--lg-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--lg-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--lg-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--lg-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--lg-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--lg-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--lg-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--lg-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--lg-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--lg-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--lg-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

@media only screen and (min-width: 1366px) {
  .grid--xl-1 > .gcell {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid--xl-2 > .gcell {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid--xl-3 > .gcell {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid--xl-4 > .gcell {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid--xl-5 > .gcell {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
  .grid--xl-6 > .gcell {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid--xl-7 > .gcell {
    width: 14.2857143%;
    max-width: 14.2857143%;
    flex-basis: 14.2857143%;
  }
  .grid--xl-8 > .gcell {
    width: 12.5%;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }
  .grid--xl-9 > .gcell {
    width: 11.1111111%;
    max-width: 11.1111111%;
    flex-basis: 11.1111111%;
  }
  .grid--xl-10 > .gcell {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .grid--xl-11 > .gcell {
    width: 9.0909091%;
    max-width: 9.0909091%;
    flex-basis: 9.0909091%;
  }
  .grid--xl-12 > .gcell {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid--xl-auto > .gcell {
    width: auto;
    max-width: none;
    flex-basis: auto;
  }
  .grid > .gcell--xl-1 {
    width: 8.3333333%;
    max-width: 8.3333333%;
    flex-basis: 8.3333333%;
  }
  .grid > .gcell--xl-2 {
    width: 16.6666667%;
    max-width: 16.6666667%;
    flex-basis: 16.6666667%;
  }
  .grid > .gcell--xl-3 {
    width: 25%;
    max-width: 25%;
    flex-basis: 25%;
  }
  .grid > .gcell--xl-4 {
    width: 33.3333333%;
    max-width: 33.3333333%;
    flex-basis: 33.3333333%;
  }
  .grid > .gcell--xl-5 {
    width: 41.6666667%;
    max-width: 41.6666667%;
    flex-basis: 41.6666667%;
  }
  .grid > .gcell--xl-6 {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
  .grid > .gcell--xl-7 {
    width: 58.3333333%;
    max-width: 58.3333333%;
    flex-basis: 58.3333333%;
  }
  .grid > .gcell--xl-8 {
    width: 66.6666667%;
    max-width: 66.6666667%;
    flex-basis: 66.6666667%;
  }
  .grid > .gcell--xl-9 {
    width: 75%;
    max-width: 75%;
    flex-basis: 75%;
  }
  .grid > .gcell--xl-10 {
    width: 83.3333333%;
    max-width: 83.3333333%;
    flex-basis: 83.3333333%;
  }
  .grid > .gcell--xl-11 {
    width: 91.6666667%;
    max-width: 91.6666667%;
    flex-basis: 91.6666667%;
  }
  .grid > .gcell--xl-12 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .grid > .gcell--xl-auto {
    width: auto;
    max-width: 100%;
    flex-basis: auto;
  }
}

.grid--inline {
  display: inline-flex;
}

.grid--items-start {
  align-items: flex-start;
}

.grid--items-end {
  align-items: flex-end;
}

.grid--items-center {
  align-items: center;
}

.grid--items-stretch {
  align-items: stretch;
}

.grid--justify-start {
  justify-content: flex-start;
}

.grid--justify-end {
  justify-content: flex-end;
}

.grid--justify-center {
  justify-content: center;
}

.grid--justify-between {
  justify-content: space-between;
}

.grid--justify-around {
  justify-content: space-around;
}

.grid--nowrap {
  flex-wrap: nowrap;
}

.grid--column {
  flex-direction: column;
}

.grid--row {
  flex-direction: row;
}

.grid--row-reverse {
  flex-direction: row-reverse;
}

.grid > .gcell--start {
  align-self: flex-start;
}

.grid > .gcell--end {
  align-self: flex-end;
}

.grid > .gcell--center {
  align-self: center;
}

.grid > .gcell--stretch {
  align-self: stretch;
}

.grid > .gcell--nogrow {
  flex-grow: 0;
}

.grid > .gcell--grow {
  flex-grow: 1;
}

.grid > .gcell--shrink {
  flex-shrink: 1;
}

.grid > .gcell--noshrink {
  flex-shrink: 0;
}

.grid > .gcell--order-start {
  order: -1;
}

.grid > .gcell--order-end {
  order: 1;
}

.grid > .gcell--noorder {
  order: 0;
}

.grid > .gcell--nobasis {
  flex-basis: 0;
}

@media only screen and (min-width: 375px) {
  .grid--xs-items-start {
    align-items: flex-start;
  }
  .grid--xs-items-end {
    align-items: flex-end;
  }
  .grid--xs-items-center {
    align-items: center;
  }
  .grid--xs-items-stretch {
    align-items: stretch;
  }
  .grid--xs-justify-start {
    justify-content: flex-start;
  }
  .grid--xs-justify-end {
    justify-content: flex-end;
  }
  .grid--xs-justify-center {
    justify-content: center;
  }
  .grid--xs-justify-between {
    justify-content: space-between;
  }
  .grid--xs-justify-around {
    justify-content: space-around;
  }
  .grid--xs-nowrap {
    flex-wrap: nowrap;
  }
  .grid--xs-wrap {
    flex-wrap: wrap;
  }
  .grid--xs-column {
    flex-direction: column;
  }
  .grid--xs-row {
    flex-direction: row;
  }
  .grid--xs-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--xs-start {
    align-self: flex-start;
  }
  .grid > .gcell--xs-end {
    align-self: flex-end;
  }
  .grid > .gcell--xs-center {
    align-self: center;
  }
  .grid > .gcell--xs-stretch {
    align-self: stretch;
  }
  .grid > .gcell--xs-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--xs-grow {
    flex-grow: 1;
  }
  .grid > .gcell--xs-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--xs-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--xs-order-start {
    order: -1;
  }
  .grid > .gcell--xs-order-end {
    order: 1;
  }
  .grid > .gcell--xs-noorder {
    order: 0;
  }
  .grid > .gcell--xs-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 480px) {
  .grid--sm-items-start {
    align-items: flex-start;
  }
  .grid--sm-items-end {
    align-items: flex-end;
  }
  .grid--sm-items-center {
    align-items: center;
  }
  .grid--sm-items-stretch {
    align-items: stretch;
  }
  .grid--sm-justify-start {
    justify-content: flex-start;
  }
  .grid--sm-justify-end {
    justify-content: flex-end;
  }
  .grid--sm-justify-center {
    justify-content: center;
  }
  .grid--sm-justify-between {
    justify-content: space-between;
  }
  .grid--sm-justify-around {
    justify-content: space-around;
  }
  .grid--sm-nowrap {
    flex-wrap: nowrap;
  }
  .grid--sm-wrap {
    flex-wrap: wrap;
  }
  .grid--sm-column {
    flex-direction: column;
  }
  .grid--sm-row {
    flex-direction: row;
  }
  .grid--sm-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--sm-start {
    align-self: flex-start;
  }
  .grid > .gcell--sm-end {
    align-self: flex-end;
  }
  .grid > .gcell--sm-center {
    align-self: center;
  }
  .grid > .gcell--sm-stretch {
    align-self: stretch;
  }
  .grid > .gcell--sm-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--sm-grow {
    flex-grow: 1;
  }
  .grid > .gcell--sm-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--sm-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--sm-order-start {
    order: -1;
  }
  .grid > .gcell--sm-order-end {
    order: 1;
  }
  .grid > .gcell--sm-noorder {
    order: 0;
  }
  .grid > .gcell--sm-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 640px) {
  .grid--ms-items-start {
    align-items: flex-start;
  }
  .grid--ms-items-end {
    align-items: flex-end;
  }
  .grid--ms-items-center {
    align-items: center;
  }
  .grid--ms-items-stretch {
    align-items: stretch;
  }
  .grid--ms-justify-start {
    justify-content: flex-start;
  }
  .grid--ms-justify-end {
    justify-content: flex-end;
  }
  .grid--ms-justify-center {
    justify-content: center;
  }
  .grid--ms-justify-between {
    justify-content: space-between;
  }
  .grid--ms-justify-around {
    justify-content: space-around;
  }
  .grid--ms-nowrap {
    flex-wrap: nowrap;
  }
  .grid--ms-wrap {
    flex-wrap: wrap;
  }
  .grid--ms-column {
    flex-direction: column;
  }
  .grid--ms-row {
    flex-direction: row;
  }
  .grid--ms-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--ms-start {
    align-self: flex-start;
  }
  .grid > .gcell--ms-end {
    align-self: flex-end;
  }
  .grid > .gcell--ms-center {
    align-self: center;
  }
  .grid > .gcell--ms-stretch {
    align-self: stretch;
  }
  .grid > .gcell--ms-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--ms-grow {
    flex-grow: 1;
  }
  .grid > .gcell--ms-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--ms-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--ms-order-start {
    order: -1;
  }
  .grid > .gcell--ms-order-end {
    order: 1;
  }
  .grid > .gcell--ms-noorder {
    order: 0;
  }
  .grid > .gcell--ms-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 768px) {
  .grid--md-items-start {
    align-items: flex-start;
  }
  .grid--md-items-end {
    align-items: flex-end;
  }
  .grid--md-items-center {
    align-items: center;
  }
  .grid--md-items-stretch {
    align-items: stretch;
  }
  .grid--md-justify-start {
    justify-content: flex-start;
  }
  .grid--md-justify-end {
    justify-content: flex-end;
  }
  .grid--md-justify-center {
    justify-content: center;
  }
  .grid--md-justify-between {
    justify-content: space-between;
  }
  .grid--md-justify-around {
    justify-content: space-around;
  }
  .grid--md-nowrap {
    flex-wrap: nowrap;
  }
  .grid--md-wrap {
    flex-wrap: wrap;
  }
  .grid--md-column {
    flex-direction: column;
  }
  .grid--md-row {
    flex-direction: row;
  }
  .grid--md-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--md-start {
    align-self: flex-start;
  }
  .grid > .gcell--md-end {
    align-self: flex-end;
  }
  .grid > .gcell--md-center {
    align-self: center;
  }
  .grid > .gcell--md-stretch {
    align-self: stretch;
  }
  .grid > .gcell--md-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--md-grow {
    flex-grow: 1;
  }
  .grid > .gcell--md-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--md-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--md-order-start {
    order: -1;
  }
  .grid > .gcell--md-order-end {
    order: 1;
  }
  .grid > .gcell--md-noorder {
    order: 0;
  }
  .grid > .gcell--md-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .grid--def-items-start {
    align-items: flex-start;
  }
  .grid--def-items-end {
    align-items: flex-end;
  }
  .grid--def-items-center {
    align-items: center;
  }
  .grid--def-items-stretch {
    align-items: stretch;
  }
  .grid--def-justify-start {
    justify-content: flex-start;
  }
  .grid--def-justify-end {
    justify-content: flex-end;
  }
  .grid--def-justify-center {
    justify-content: center;
  }
  .grid--def-justify-between {
    justify-content: space-between;
  }
  .grid--def-justify-around {
    justify-content: space-around;
  }
  .grid--def-nowrap {
    flex-wrap: nowrap;
  }
  .grid--def-wrap {
    flex-wrap: wrap;
  }
  .grid--def-column {
    flex-direction: column;
  }
  .grid--def-row {
    flex-direction: row;
  }
  .grid--def-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--def-start {
    align-self: flex-start;
  }
  .grid > .gcell--def-end {
    align-self: flex-end;
  }
  .grid > .gcell--def-center {
    align-self: center;
  }
  .grid > .gcell--def-stretch {
    align-self: stretch;
  }
  .grid > .gcell--def-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--def-grow {
    flex-grow: 1;
  }
  .grid > .gcell--def-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--def-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--def-order-start {
    order: -1;
  }
  .grid > .gcell--def-order-end {
    order: 1;
  }
  .grid > .gcell--def-noorder {
    order: 0;
  }
  .grid > .gcell--def-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .grid--lg-items-start {
    align-items: flex-start;
  }
  .grid--lg-items-end {
    align-items: flex-end;
  }
  .grid--lg-items-center {
    align-items: center;
  }
  .grid--lg-items-stretch {
    align-items: stretch;
  }
  .grid--lg-justify-start {
    justify-content: flex-start;
  }
  .grid--lg-justify-end {
    justify-content: flex-end;
  }
  .grid--lg-justify-center {
    justify-content: center;
  }
  .grid--lg-justify-between {
    justify-content: space-between;
  }
  .grid--lg-justify-around {
    justify-content: space-around;
  }
  .grid--lg-nowrap {
    flex-wrap: nowrap;
  }
  .grid--lg-wrap {
    flex-wrap: wrap;
  }
  .grid--lg-column {
    flex-direction: column;
  }
  .grid--lg-row {
    flex-direction: row;
  }
  .grid--lg-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--lg-start {
    align-self: flex-start;
  }
  .grid > .gcell--lg-end {
    align-self: flex-end;
  }
  .grid > .gcell--lg-center {
    align-self: center;
  }
  .grid > .gcell--lg-stretch {
    align-self: stretch;
  }
  .grid > .gcell--lg-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--lg-grow {
    flex-grow: 1;
  }
  .grid > .gcell--lg-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--lg-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--lg-order-start {
    order: -1;
  }
  .grid > .gcell--lg-order-end {
    order: 1;
  }
  .grid > .gcell--lg-noorder {
    order: 0;
  }
  .grid > .gcell--lg-nobasis {
    flex-basis: 0;
  }
}

@media only screen and (min-width: 1366px) {
  .grid--xl-items-start {
    align-items: flex-start;
  }
  .grid--xl-items-end {
    align-items: flex-end;
  }
  .grid--xl-items-center {
    align-items: center;
  }
  .grid--xl-items-stretch {
    align-items: stretch;
  }
  .grid--xl-justify-start {
    justify-content: flex-start;
  }
  .grid--xl-justify-end {
    justify-content: flex-end;
  }
  .grid--xl-justify-center {
    justify-content: center;
  }
  .grid--xl-justify-between {
    justify-content: space-between;
  }
  .grid--xl-justify-around {
    justify-content: space-around;
  }
  .grid--xl-nowrap {
    flex-wrap: nowrap;
  }
  .grid--xl-wrap {
    flex-wrap: wrap;
  }
  .grid--xl-column {
    flex-direction: column;
  }
  .grid--xl-row {
    flex-direction: row;
  }
  .grid--xl-row-reverse {
    flex-direction: row-reverse;
  }
  .grid > .gcell--xl-start {
    align-self: flex-start;
  }
  .grid > .gcell--xl-end {
    align-self: flex-end;
  }
  .grid > .gcell--xl-center {
    align-self: center;
  }
  .grid > .gcell--xl-stretch {
    align-self: stretch;
  }
  .grid > .gcell--xl-nogrow {
    flex-grow: 0;
  }
  .grid > .gcell--xl-grow {
    flex-grow: 1;
  }
  .grid > .gcell--xl-shrink {
    flex-shrink: 1;
  }
  .grid > .gcell--xl-noshrink {
    flex-shrink: 0;
  }
  .grid > .gcell--xl-order-start {
    order: -1;
  }
  .grid > .gcell--xl-order-end {
    order: 1;
  }
  .grid > .gcell--xl-noorder {
    order: 0;
  }
  .grid > .gcell--xl-nobasis {
    flex-basis: 0;
  }
}

.grid > .gcell--parser-half {
  width: 360px;
  max-width: 360px;
  flex-basis: 360px;
}

@media only screen and (min-width: 375px) {
  .grid--xs-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--xs-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--xs-vspace-sm {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
  .grid--xs-vspace-sm > .gcell {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }
  .grid--xs-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--xs-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--xs-vspace-md {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .grid--xs-vspace-md > .gcell {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .grid--xs-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--xs-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--xs-vspace-def {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .grid--xs-vspace-def > .gcell {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .grid--xs-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--xs-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--xs-vspace-lg {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .grid--xs-vspace-lg > .gcell {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .grid--xs-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--xs-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--xs-vspace-xl {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .grid--xs-vspace-xl > .gcell {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .grid--xs-space-mg {
    margin-left: -25px;
    margin-right: -25px;
  }
  .grid--xs-space-mg > .gcell {
    padding-left: 25px;
    padding-right: 25px;
  }
  .grid--xs-vspace-mg {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .grid--xs-vspace-mg > .gcell {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .grid--xs-nospace {
    margin: 0;
  }
  .grid--xs-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 480px) {
  .grid--sm-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--sm-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--sm-vspace-sm {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
  .grid--sm-vspace-sm > .gcell {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }
  .grid--sm-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--sm-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--sm-vspace-md {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .grid--sm-vspace-md > .gcell {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .grid--sm-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--sm-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--sm-vspace-def {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .grid--sm-vspace-def > .gcell {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .grid--sm-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--sm-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--sm-vspace-lg {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .grid--sm-vspace-lg > .gcell {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .grid--sm-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--sm-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--sm-vspace-xl {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .grid--sm-vspace-xl > .gcell {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .grid--sm-space-mg {
    margin-left: -25px;
    margin-right: -25px;
  }
  .grid--sm-space-mg > .gcell {
    padding-left: 25px;
    padding-right: 25px;
  }
  .grid--sm-vspace-mg {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .grid--sm-vspace-mg > .gcell {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .grid--sm-nospace {
    margin: 0;
  }
  .grid--sm-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 640px) {
  .grid--ms-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--ms-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--ms-vspace-sm {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
  .grid--ms-vspace-sm > .gcell {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }
  .grid--ms-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--ms-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--ms-vspace-md {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .grid--ms-vspace-md > .gcell {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .grid--ms-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--ms-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--ms-vspace-def {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .grid--ms-vspace-def > .gcell {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .grid--ms-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--ms-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--ms-vspace-lg {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .grid--ms-vspace-lg > .gcell {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .grid--ms-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--ms-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--ms-vspace-xl {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .grid--ms-vspace-xl > .gcell {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .grid--ms-space-mg {
    margin-left: -25px;
    margin-right: -25px;
  }
  .grid--ms-space-mg > .gcell {
    padding-left: 25px;
    padding-right: 25px;
  }
  .grid--ms-vspace-mg {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .grid--ms-vspace-mg > .gcell {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .grid--ms-nospace {
    margin: 0;
  }
  .grid--ms-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) {
  .grid--md-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--md-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--md-vspace-sm {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
  .grid--md-vspace-sm > .gcell {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }
  .grid--md-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--md-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--md-vspace-md {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .grid--md-vspace-md > .gcell {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .grid--md-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--md-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--md-vspace-def {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .grid--md-vspace-def > .gcell {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .grid--md-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--md-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--md-vspace-lg {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .grid--md-vspace-lg > .gcell {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .grid--md-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--md-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--md-vspace-xl {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .grid--md-vspace-xl > .gcell {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .grid--md-space-mg {
    margin-left: -25px;
    margin-right: -25px;
  }
  .grid--md-space-mg > .gcell {
    padding-left: 25px;
    padding-right: 25px;
  }
  .grid--md-vspace-mg {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .grid--md-vspace-mg > .gcell {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .grid--md-nospace {
    margin: 0;
  }
  .grid--md-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .grid--def-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--def-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--def-vspace-sm {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
  .grid--def-vspace-sm > .gcell {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }
  .grid--def-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--def-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--def-vspace-md {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .grid--def-vspace-md > .gcell {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .grid--def-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--def-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--def-vspace-def {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .grid--def-vspace-def > .gcell {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .grid--def-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--def-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--def-vspace-lg {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .grid--def-vspace-lg > .gcell {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .grid--def-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--def-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--def-vspace-xl {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .grid--def-vspace-xl > .gcell {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .grid--def-space-mg {
    margin-left: -25px;
    margin-right: -25px;
  }
  .grid--def-space-mg > .gcell {
    padding-left: 25px;
    padding-right: 25px;
  }
  .grid--def-vspace-mg {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .grid--def-vspace-mg > .gcell {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .grid--def-nospace {
    margin: 0;
  }
  .grid--def-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 1280px) {
  .grid--lg-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--lg-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--lg-vspace-sm {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
  .grid--lg-vspace-sm > .gcell {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }
  .grid--lg-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--lg-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--lg-vspace-md {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .grid--lg-vspace-md > .gcell {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .grid--lg-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--lg-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--lg-vspace-def {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .grid--lg-vspace-def > .gcell {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .grid--lg-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--lg-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--lg-vspace-lg {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .grid--lg-vspace-lg > .gcell {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .grid--lg-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--lg-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--lg-vspace-xl {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .grid--lg-vspace-xl > .gcell {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .grid--lg-space-mg {
    margin-left: -25px;
    margin-right: -25px;
  }
  .grid--lg-space-mg > .gcell {
    padding-left: 25px;
    padding-right: 25px;
  }
  .grid--lg-vspace-mg {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .grid--lg-vspace-mg > .gcell {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .grid--lg-nospace {
    margin: 0;
  }
  .grid--lg-nospace > .gcell {
    padding: 0;
  }
}

@media only screen and (min-width: 1366px) {
  .grid--xl-space-sm {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .grid--xl-space-sm > .gcell {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .grid--xl-vspace-sm {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
  .grid--xl-vspace-sm > .gcell {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }
  .grid--xl-space-md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .grid--xl-space-md > .gcell {
    padding-left: 5px;
    padding-right: 5px;
  }
  .grid--xl-vspace-md {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .grid--xl-vspace-md > .gcell {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .grid--xl-space-def {
    margin-left: -10px;
    margin-right: -10px;
  }
  .grid--xl-space-def > .gcell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .grid--xl-vspace-def {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .grid--xl-vspace-def > .gcell {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .grid--xl-space-lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid--xl-space-lg > .gcell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid--xl-vspace-lg {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .grid--xl-vspace-lg > .gcell {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .grid--xl-space-xl {
    margin-left: -20px;
    margin-right: -20px;
  }
  .grid--xl-space-xl > .gcell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid--xl-vspace-xl {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .grid--xl-vspace-xl > .gcell {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .grid--xl-space-mg {
    margin-left: -25px;
    margin-right: -25px;
  }
  .grid--xl-space-mg > .gcell {
    padding-left: 25px;
    padding-right: 25px;
  }
  .grid--xl-vspace-mg {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .grid--xl-vspace-mg > .gcell {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .grid--xl-nospace {
    margin: 0;
  }
  .grid--xl-nospace > .gcell {
    padding: 0;
  }
}

._color-black {
  color: #000;
}

._color-gray90 {
  color: #1a1a1a;
}

._color-gray75 {
  color: #404040;
}

._color-gray50 {
  color: #807f7f;
}

._color-gray25 {
  color: #bfbfbf;
}

._color-gray10 {
  color: #e6e6e6;
}

._color-white {
  color: #fff;
}

._color-link {
  color: #43f;
}

._color-primary {
  color: #8074ff;
}

._color-secondary {
  color: #e652a6;
}

._color-success {
  color: #c1af7a;
}

._color-info {
  color: #00b3fe;
}

._color-warning {
  color: #c89600;
}

._color-danger {
  color: #ff6262;
}

._bgcolor-black {
  background-color: #000;
}

._bgcolor-gray90 {
  background-color: #1a1a1a;
}

._bgcolor-gray75 {
  background-color: #404040;
}

._bgcolor-gray50 {
  background-color: #807f7f;
}

._bgcolor-gray25 {
  background-color: #bfbfbf;
}

._bgcolor-gray10 {
  background-color: #e6e6e6;
}

._bgcolor-white {
  background-color: #fff;
}

._bgcolor-link {
  background-color: #43f;
}

._bgcolor-primary {
  background-color: #8074ff;
}

._bgcolor-secondary {
  background-color: #e652a6;
}

._bgcolor-success {
  background-color: #c1af7a;
}

._bgcolor-info {
  background-color: #00b3fe;
}

._bgcolor-warning {
  background-color: #c89600;
}

._bgcolor-danger {
  background-color: #ff6262;
}

._fill-black {
  fill: #000;
}

._fill-gray90 {
  fill: #1a1a1a;
}

._fill-gray75 {
  fill: #404040;
}

._fill-gray50 {
  fill: #807f7f;
}

._fill-gray25 {
  fill: #bfbfbf;
}

._fill-gray10 {
  fill: #e6e6e6;
}

._fill-white {
  fill: #fff;
}

._fill-link {
  fill: #43f;
}

._fill-primary {
  fill: #8074ff;
}

._fill-secondary {
  fill: #e652a6;
}

._fill-success {
  fill: #c1af7a;
}

._fill-info {
  fill: #00b3fe;
}

._fill-warning {
  fill: #c89600;
}

._fill-danger {
  fill: #ff6262;
}

._left {
  float: left;
}

._right {
  float: right;
}

._overflow {
  overflow: hidden;
}

._clear {
  clear: both;
}

._clear-after:after {
  content: '';
  clear: both;
  display: block;
  height: 0;
}

._no-hover {
  cursor: default !important;
  transform: none !important;
}

._dib {
  display: inline-block;
}

._db {
  display: inline-block;
}

@media only screen and (min-width: 375px) {
  ._xs-left {
    float: left;
  }
  ._xs-right {
    float: right;
  }
  ._xs-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-left {
    float: left;
  }
  ._sm-right {
    float: right;
  }
  ._sm-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-left {
    float: left;
  }
  ._ms-right {
    float: right;
  }
  ._ms-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 768px) {
  ._md-left {
    float: left;
  }
  ._md-right {
    float: right;
  }
  ._md-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1024px) {
  ._def-left {
    float: left;
  }
  ._def-right {
    float: right;
  }
  ._def-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-left {
    float: left;
  }
  ._lg-right {
    float: right;
  }
  ._lg-overflow {
    overflow: hidden;
  }
}

@media only screen and (min-width: 1366px) {
  ._xl-left {
    float: left;
  }
  ._xl-right {
    float: right;
  }
  ._xl-overflow {
    overflow: hidden;
  }
}

._text-left {
  text-align: left;
}

._text-right {
  text-align: right;
}

._text-center {
  text-align: center;
}

._ellipsis {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 375px) {
  ._xs-text-left {
    text-align: left;
  }
  ._xs-text-right {
    text-align: right;
  }
  ._xs-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-text-left {
    text-align: left;
  }
  ._sm-text-right {
    text-align: right;
  }
  ._sm-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-text-left {
    text-align: left;
  }
  ._ms-text-right {
    text-align: right;
  }
  ._ms-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  ._md-text-left {
    text-align: left;
  }
  ._md-text-right {
    text-align: right;
  }
  ._md-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1024px) {
  ._def-text-left {
    text-align: left;
  }
  ._def-text-right {
    text-align: right;
  }
  ._def-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-text-left {
    text-align: left;
  }
  ._lg-text-right {
    text-align: right;
  }
  ._lg-text-center {
    text-align: center;
  }
}

@media only screen and (min-width: 1366px) {
  ._xl-text-left {
    text-align: left;
  }
  ._xl-text-right {
    text-align: right;
  }
  ._xl-text-center {
    text-align: center;
  }
}

._hide {
  display: none;
}

._hide\! {
  display: none !important;
}

@media print {
  ._noprint {
    display: none !important;
  }
}

@media only screen and (min-width: 375px) {
  ._xs-hide {
    display: none;
  }
  ._xs-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 374px) {
  ._xs-show {
    display: none;
  }
  ._xs-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-hide {
    display: none;
  }
  ._sm-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 479px) {
  ._sm-show {
    display: none;
  }
  ._sm-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-hide {
    display: none;
  }
  ._ms-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 639px) {
  ._ms-show {
    display: none;
  }
  ._ms-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  ._md-hide {
    display: none;
  }
  ._md-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  ._md-show {
    display: none;
  }
  ._md-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  ._def-hide {
    display: none;
  }
  ._def-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1023px) {
  ._def-show {
    display: none;
  }
  ._def-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-hide {
    display: none;
  }
  ._lg-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1279px) {
  ._lg-show {
    display: none;
  }
  ._lg-show\! {
    display: none !important;
  }
}

@media only screen and (min-width: 1366px) {
  ._xl-hide {
    display: none;
  }
  ._xl-hide\! {
    display: none !important;
  }
}

@media only screen and (max-width: 1365px) {
  ._xl-show {
    display: none;
  }
  ._xl-show\! {
    display: none !important;
  }
}

._m-sm {
  margin: 0.3125rem !important;
}

._mtb-sm {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important;
}

._mlr-sm {
  margin-left: 0.3125rem !important;
  margin-right: 0.3125rem !important;
}

._m-md {
  margin: 0.625rem !important;
}

._mtb-md {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

._mlr-md {
  margin-left: 0.625rem !important;
  margin-right: 0.625rem !important;
}

._m-def {
  margin: 1.25rem !important;
}

._mtb-def {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

._mlr-def {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

._m-lg {
  margin: 1.875rem !important;
}

._mtb-lg {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

._mlr-lg {
  margin-left: 1.875rem !important;
  margin-right: 1.875rem !important;
}

._m-xl {
  margin: 2.5rem !important;
}

._mtb-xl {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

._mlr-xl {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

._m-mg {
  margin: 3.125rem !important;
}

._mtb-mg {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

._mlr-mg {
  margin-left: 3.125rem !important;
  margin-right: 3.125rem !important;
}

._mt-sm {
  margin-top: 0.3125rem !important;
}

._mt-md {
  margin-top: 0.625rem !important;
}

._mt-def {
  margin-top: 1.25rem !important;
}

._mt-lg {
  margin-top: 1.875rem !important;
}

._mt-xl {
  margin-top: 2.5rem !important;
}

._mt-mg {
  margin-top: 3.125rem !important;
}

._mr-sm {
  margin-right: 0.3125rem !important;
}

._mr-md {
  margin-right: 0.625rem !important;
}

._mr-def {
  margin-right: 1.25rem !important;
}

._mr-lg {
  margin-right: 1.875rem !important;
}

._mr-xl {
  margin-right: 2.5rem !important;
}

._mr-mg {
  margin-right: 3.125rem !important;
}

._mb-sm {
  margin-bottom: 0.3125rem !important;
}

._mb-md {
  margin-bottom: 0.625rem !important;
}

._mb-def {
  margin-bottom: 1.25rem !important;
}

._mb-lg {
  margin-bottom: 1.875rem !important;
}

._mb-xl {
  margin-bottom: 2.5rem !important;
}

._mb-mg {
  margin-bottom: 3.125rem !important;
}

._ml-sm {
  margin-left: 0.3125rem !important;
}

._ml-md {
  margin-left: 0.625rem !important;
}

._ml-def {
  margin-left: 1.25rem !important;
}

._ml-lg {
  margin-left: 1.875rem !important;
}

._ml-xl {
  margin-left: 2.5rem !important;
}

._ml-mg {
  margin-left: 3.125rem !important;
}

._p-sm {
  padding: 0.3125rem !important;
}

._ptb-sm {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

._plr-sm {
  padding-left: 0.3125rem !important;
  padding-right: 0.3125rem !important;
}

._p-md {
  padding: 0.625rem !important;
}

._ptb-md {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

._plr-md {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

._p-def {
  padding: 1.25rem !important;
}

._ptb-def {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

._plr-def {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

._p-lg {
  padding: 1.875rem !important;
}

._ptb-lg {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

._plr-lg {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
}

._p-xl {
  padding: 2.5rem !important;
}

._ptb-xl {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

._plr-xl {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

._p-mg {
  padding: 3.125rem !important;
}

._ptb-mg {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

._plr-mg {
  padding-left: 3.125rem !important;
  padding-right: 3.125rem !important;
}

._pt-sm {
  padding-top: 0.3125rem !important;
}

._pt-md {
  padding-top: 0.625rem !important;
}

._pt-def {
  padding-top: 1.25rem !important;
}

._pt-lg {
  padding-top: 1.875rem !important;
}

._pt-xl {
  padding-top: 2.5rem !important;
}

._pt-mg {
  padding-top: 3.125rem !important;
}

._pr-sm {
  padding-right: 0.3125rem !important;
}

._pr-md {
  padding-right: 0.625rem !important;
}

._pr-def {
  padding-right: 1.25rem !important;
}

._pr-lg {
  padding-right: 1.875rem !important;
}

._pr-xl {
  padding-right: 2.5rem !important;
}

._pr-mg {
  padding-right: 3.125rem !important;
}

._pb-sm {
  padding-bottom: 0.3125rem !important;
}

._pb-md {
  padding-bottom: 0.625rem !important;
}

._pb-def {
  padding-bottom: 1.25rem !important;
}

._pb-lg {
  padding-bottom: 1.875rem !important;
}

._pb-xl {
  padding-bottom: 2.5rem !important;
}

._pb-mg {
  padding-bottom: 3.125rem !important;
}

._pl-sm {
  padding-left: 0.3125rem !important;
}

._pl-md {
  padding-left: 0.625rem !important;
}

._pl-def {
  padding-left: 1.25rem !important;
}

._pl-lg {
  padding-left: 1.875rem !important;
}

._pl-xl {
  padding-left: 2.5rem !important;
}

._pl-mg {
  padding-left: 3.125rem !important;
}

._m-auto {
  margin: auto !important;
}

._ml-auto {
  margin-left: auto !important;
}

._mr-auto {
  margin-right: auto !important;
}

._mlr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media only screen and (min-width: 375px) {
  ._xs-m-sm {
    margin: 0.3125rem !important;
  }
  ._xs-mtb-sm {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  ._xs-mlr-sm {
    margin-left: 0.3125rem !important;
    margin-right: 0.3125rem !important;
  }
  ._xs-m-md {
    margin: 0.625rem !important;
  }
  ._xs-mtb-md {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  ._xs-mlr-md {
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }
  ._xs-m-def {
    margin: 1.25rem !important;
  }
  ._xs-mtb-def {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  ._xs-mlr-def {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  ._xs-m-lg {
    margin: 1.875rem !important;
  }
  ._xs-mtb-lg {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  ._xs-mlr-lg {
    margin-left: 1.875rem !important;
    margin-right: 1.875rem !important;
  }
  ._xs-m-xl {
    margin: 2.5rem !important;
  }
  ._xs-mtb-xl {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  ._xs-mlr-xl {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  ._xs-m-mg {
    margin: 3.125rem !important;
  }
  ._xs-mtb-mg {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  ._xs-mlr-mg {
    margin-left: 3.125rem !important;
    margin-right: 3.125rem !important;
  }
  ._xs-mt-sm {
    margin-top: 0.3125rem !important;
  }
  ._xs-mt-md {
    margin-top: 0.625rem !important;
  }
  ._xs-mt-def {
    margin-top: 1.25rem !important;
  }
  ._xs-mt-lg {
    margin-top: 1.875rem !important;
  }
  ._xs-mt-xl {
    margin-top: 2.5rem !important;
  }
  ._xs-mt-mg {
    margin-top: 3.125rem !important;
  }
  ._xs-mr-sm {
    margin-right: 0.3125rem !important;
  }
  ._xs-mr-md {
    margin-right: 0.625rem !important;
  }
  ._xs-mr-def {
    margin-right: 1.25rem !important;
  }
  ._xs-mr-lg {
    margin-right: 1.875rem !important;
  }
  ._xs-mr-xl {
    margin-right: 2.5rem !important;
  }
  ._xs-mr-mg {
    margin-right: 3.125rem !important;
  }
  ._xs-mb-sm {
    margin-bottom: 0.3125rem !important;
  }
  ._xs-mb-md {
    margin-bottom: 0.625rem !important;
  }
  ._xs-mb-def {
    margin-bottom: 1.25rem !important;
  }
  ._xs-mb-lg {
    margin-bottom: 1.875rem !important;
  }
  ._xs-mb-xl {
    margin-bottom: 2.5rem !important;
  }
  ._xs-mb-mg {
    margin-bottom: 3.125rem !important;
  }
  ._xs-ml-sm {
    margin-left: 0.3125rem !important;
  }
  ._xs-ml-md {
    margin-left: 0.625rem !important;
  }
  ._xs-ml-def {
    margin-left: 1.25rem !important;
  }
  ._xs-ml-lg {
    margin-left: 1.875rem !important;
  }
  ._xs-ml-xl {
    margin-left: 2.5rem !important;
  }
  ._xs-ml-mg {
    margin-left: 3.125rem !important;
  }
  ._xs-p-sm {
    padding: 0.3125rem !important;
  }
  ._xs-ptb-sm {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  ._xs-plr-sm {
    padding-left: 0.3125rem !important;
    padding-right: 0.3125rem !important;
  }
  ._xs-p-md {
    padding: 0.625rem !important;
  }
  ._xs-ptb-md {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  ._xs-plr-md {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  ._xs-p-def {
    padding: 1.25rem !important;
  }
  ._xs-ptb-def {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  ._xs-plr-def {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  ._xs-p-lg {
    padding: 1.875rem !important;
  }
  ._xs-ptb-lg {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  ._xs-plr-lg {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  ._xs-p-xl {
    padding: 2.5rem !important;
  }
  ._xs-ptb-xl {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  ._xs-plr-xl {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  ._xs-p-mg {
    padding: 3.125rem !important;
  }
  ._xs-ptb-mg {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  ._xs-plr-mg {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  ._xs-pt-sm {
    padding-top: 0.3125rem !important;
  }
  ._xs-pt-md {
    padding-top: 0.625rem !important;
  }
  ._xs-pt-def {
    padding-top: 1.25rem !important;
  }
  ._xs-pt-lg {
    padding-top: 1.875rem !important;
  }
  ._xs-pt-xl {
    padding-top: 2.5rem !important;
  }
  ._xs-pt-mg {
    padding-top: 3.125rem !important;
  }
  ._xs-pr-sm {
    padding-right: 0.3125rem !important;
  }
  ._xs-pr-md {
    padding-right: 0.625rem !important;
  }
  ._xs-pr-def {
    padding-right: 1.25rem !important;
  }
  ._xs-pr-lg {
    padding-right: 1.875rem !important;
  }
  ._xs-pr-xl {
    padding-right: 2.5rem !important;
  }
  ._xs-pr-mg {
    padding-right: 3.125rem !important;
  }
  ._xs-pb-sm {
    padding-bottom: 0.3125rem !important;
  }
  ._xs-pb-md {
    padding-bottom: 0.625rem !important;
  }
  ._xs-pb-def {
    padding-bottom: 1.25rem !important;
  }
  ._xs-pb-lg {
    padding-bottom: 1.875rem !important;
  }
  ._xs-pb-xl {
    padding-bottom: 2.5rem !important;
  }
  ._xs-pb-mg {
    padding-bottom: 3.125rem !important;
  }
  ._xs-pl-sm {
    padding-left: 0.3125rem !important;
  }
  ._xs-pl-md {
    padding-left: 0.625rem !important;
  }
  ._xs-pl-def {
    padding-left: 1.25rem !important;
  }
  ._xs-pl-lg {
    padding-left: 1.875rem !important;
  }
  ._xs-pl-xl {
    padding-left: 2.5rem !important;
  }
  ._xs-pl-mg {
    padding-left: 3.125rem !important;
  }
  ._xs-m-auto {
    margin: auto !important;
  }
  ._xs-ml-auto {
    margin-left: auto !important;
  }
  ._xs-mr-auto {
    margin-right: auto !important;
  }
  ._xs-mlr-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 480px) {
  ._sm-m-sm {
    margin: 0.3125rem !important;
  }
  ._sm-mtb-sm {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  ._sm-mlr-sm {
    margin-left: 0.3125rem !important;
    margin-right: 0.3125rem !important;
  }
  ._sm-m-md {
    margin: 0.625rem !important;
  }
  ._sm-mtb-md {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  ._sm-mlr-md {
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }
  ._sm-m-def {
    margin: 1.25rem !important;
  }
  ._sm-mtb-def {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  ._sm-mlr-def {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  ._sm-m-lg {
    margin: 1.875rem !important;
  }
  ._sm-mtb-lg {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  ._sm-mlr-lg {
    margin-left: 1.875rem !important;
    margin-right: 1.875rem !important;
  }
  ._sm-m-xl {
    margin: 2.5rem !important;
  }
  ._sm-mtb-xl {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  ._sm-mlr-xl {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  ._sm-m-mg {
    margin: 3.125rem !important;
  }
  ._sm-mtb-mg {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  ._sm-mlr-mg {
    margin-left: 3.125rem !important;
    margin-right: 3.125rem !important;
  }
  ._sm-mt-sm {
    margin-top: 0.3125rem !important;
  }
  ._sm-mt-md {
    margin-top: 0.625rem !important;
  }
  ._sm-mt-def {
    margin-top: 1.25rem !important;
  }
  ._sm-mt-lg {
    margin-top: 1.875rem !important;
  }
  ._sm-mt-xl {
    margin-top: 2.5rem !important;
  }
  ._sm-mt-mg {
    margin-top: 3.125rem !important;
  }
  ._sm-mr-sm {
    margin-right: 0.3125rem !important;
  }
  ._sm-mr-md {
    margin-right: 0.625rem !important;
  }
  ._sm-mr-def {
    margin-right: 1.25rem !important;
  }
  ._sm-mr-lg {
    margin-right: 1.875rem !important;
  }
  ._sm-mr-xl {
    margin-right: 2.5rem !important;
  }
  ._sm-mr-mg {
    margin-right: 3.125rem !important;
  }
  ._sm-mb-sm {
    margin-bottom: 0.3125rem !important;
  }
  ._sm-mb-md {
    margin-bottom: 0.625rem !important;
  }
  ._sm-mb-def {
    margin-bottom: 1.25rem !important;
  }
  ._sm-mb-lg {
    margin-bottom: 1.875rem !important;
  }
  ._sm-mb-xl {
    margin-bottom: 2.5rem !important;
  }
  ._sm-mb-mg {
    margin-bottom: 3.125rem !important;
  }
  ._sm-ml-sm {
    margin-left: 0.3125rem !important;
  }
  ._sm-ml-md {
    margin-left: 0.625rem !important;
  }
  ._sm-ml-def {
    margin-left: 1.25rem !important;
  }
  ._sm-ml-lg {
    margin-left: 1.875rem !important;
  }
  ._sm-ml-xl {
    margin-left: 2.5rem !important;
  }
  ._sm-ml-mg {
    margin-left: 3.125rem !important;
  }
  ._sm-p-sm {
    padding: 0.3125rem !important;
  }
  ._sm-ptb-sm {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  ._sm-plr-sm {
    padding-left: 0.3125rem !important;
    padding-right: 0.3125rem !important;
  }
  ._sm-p-md {
    padding: 0.625rem !important;
  }
  ._sm-ptb-md {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  ._sm-plr-md {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  ._sm-p-def {
    padding: 1.25rem !important;
  }
  ._sm-ptb-def {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  ._sm-plr-def {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  ._sm-p-lg {
    padding: 1.875rem !important;
  }
  ._sm-ptb-lg {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  ._sm-plr-lg {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  ._sm-p-xl {
    padding: 2.5rem !important;
  }
  ._sm-ptb-xl {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  ._sm-plr-xl {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  ._sm-p-mg {
    padding: 3.125rem !important;
  }
  ._sm-ptb-mg {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  ._sm-plr-mg {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  ._sm-pt-sm {
    padding-top: 0.3125rem !important;
  }
  ._sm-pt-md {
    padding-top: 0.625rem !important;
  }
  ._sm-pt-def {
    padding-top: 1.25rem !important;
  }
  ._sm-pt-lg {
    padding-top: 1.875rem !important;
  }
  ._sm-pt-xl {
    padding-top: 2.5rem !important;
  }
  ._sm-pt-mg {
    padding-top: 3.125rem !important;
  }
  ._sm-pr-sm {
    padding-right: 0.3125rem !important;
  }
  ._sm-pr-md {
    padding-right: 0.625rem !important;
  }
  ._sm-pr-def {
    padding-right: 1.25rem !important;
  }
  ._sm-pr-lg {
    padding-right: 1.875rem !important;
  }
  ._sm-pr-xl {
    padding-right: 2.5rem !important;
  }
  ._sm-pr-mg {
    padding-right: 3.125rem !important;
  }
  ._sm-pb-sm {
    padding-bottom: 0.3125rem !important;
  }
  ._sm-pb-md {
    padding-bottom: 0.625rem !important;
  }
  ._sm-pb-def {
    padding-bottom: 1.25rem !important;
  }
  ._sm-pb-lg {
    padding-bottom: 1.875rem !important;
  }
  ._sm-pb-xl {
    padding-bottom: 2.5rem !important;
  }
  ._sm-pb-mg {
    padding-bottom: 3.125rem !important;
  }
  ._sm-pl-sm {
    padding-left: 0.3125rem !important;
  }
  ._sm-pl-md {
    padding-left: 0.625rem !important;
  }
  ._sm-pl-def {
    padding-left: 1.25rem !important;
  }
  ._sm-pl-lg {
    padding-left: 1.875rem !important;
  }
  ._sm-pl-xl {
    padding-left: 2.5rem !important;
  }
  ._sm-pl-mg {
    padding-left: 3.125rem !important;
  }
  ._sm-m-auto {
    margin: auto !important;
  }
  ._sm-ml-auto {
    margin-left: auto !important;
  }
  ._sm-mr-auto {
    margin-right: auto !important;
  }
  ._sm-mlr-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 640px) {
  ._ms-m-sm {
    margin: 0.3125rem !important;
  }
  ._ms-mtb-sm {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  ._ms-mlr-sm {
    margin-left: 0.3125rem !important;
    margin-right: 0.3125rem !important;
  }
  ._ms-m-md {
    margin: 0.625rem !important;
  }
  ._ms-mtb-md {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  ._ms-mlr-md {
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }
  ._ms-m-def {
    margin: 1.25rem !important;
  }
  ._ms-mtb-def {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  ._ms-mlr-def {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  ._ms-m-lg {
    margin: 1.875rem !important;
  }
  ._ms-mtb-lg {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  ._ms-mlr-lg {
    margin-left: 1.875rem !important;
    margin-right: 1.875rem !important;
  }
  ._ms-m-xl {
    margin: 2.5rem !important;
  }
  ._ms-mtb-xl {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  ._ms-mlr-xl {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  ._ms-m-mg {
    margin: 3.125rem !important;
  }
  ._ms-mtb-mg {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  ._ms-mlr-mg {
    margin-left: 3.125rem !important;
    margin-right: 3.125rem !important;
  }
  ._ms-mt-sm {
    margin-top: 0.3125rem !important;
  }
  ._ms-mt-md {
    margin-top: 0.625rem !important;
  }
  ._ms-mt-def {
    margin-top: 1.25rem !important;
  }
  ._ms-mt-lg {
    margin-top: 1.875rem !important;
  }
  ._ms-mt-xl {
    margin-top: 2.5rem !important;
  }
  ._ms-mt-mg {
    margin-top: 3.125rem !important;
  }
  ._ms-mr-sm {
    margin-right: 0.3125rem !important;
  }
  ._ms-mr-md {
    margin-right: 0.625rem !important;
  }
  ._ms-mr-def {
    margin-right: 1.25rem !important;
  }
  ._ms-mr-lg {
    margin-right: 1.875rem !important;
  }
  ._ms-mr-xl {
    margin-right: 2.5rem !important;
  }
  ._ms-mr-mg {
    margin-right: 3.125rem !important;
  }
  ._ms-mb-sm {
    margin-bottom: 0.3125rem !important;
  }
  ._ms-mb-md {
    margin-bottom: 0.625rem !important;
  }
  ._ms-mb-def {
    margin-bottom: 1.25rem !important;
  }
  ._ms-mb-lg {
    margin-bottom: 1.875rem !important;
  }
  ._ms-mb-xl {
    margin-bottom: 2.5rem !important;
  }
  ._ms-mb-mg {
    margin-bottom: 3.125rem !important;
  }
  ._ms-ml-sm {
    margin-left: 0.3125rem !important;
  }
  ._ms-ml-md {
    margin-left: 0.625rem !important;
  }
  ._ms-ml-def {
    margin-left: 1.25rem !important;
  }
  ._ms-ml-lg {
    margin-left: 1.875rem !important;
  }
  ._ms-ml-xl {
    margin-left: 2.5rem !important;
  }
  ._ms-ml-mg {
    margin-left: 3.125rem !important;
  }
  ._ms-p-sm {
    padding: 0.3125rem !important;
  }
  ._ms-ptb-sm {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  ._ms-plr-sm {
    padding-left: 0.3125rem !important;
    padding-right: 0.3125rem !important;
  }
  ._ms-p-md {
    padding: 0.625rem !important;
  }
  ._ms-ptb-md {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  ._ms-plr-md {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  ._ms-p-def {
    padding: 1.25rem !important;
  }
  ._ms-ptb-def {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  ._ms-plr-def {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  ._ms-p-lg {
    padding: 1.875rem !important;
  }
  ._ms-ptb-lg {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  ._ms-plr-lg {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  ._ms-p-xl {
    padding: 2.5rem !important;
  }
  ._ms-ptb-xl {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  ._ms-plr-xl {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  ._ms-p-mg {
    padding: 3.125rem !important;
  }
  ._ms-ptb-mg {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  ._ms-plr-mg {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  ._ms-pt-sm {
    padding-top: 0.3125rem !important;
  }
  ._ms-pt-md {
    padding-top: 0.625rem !important;
  }
  ._ms-pt-def {
    padding-top: 1.25rem !important;
  }
  ._ms-pt-lg {
    padding-top: 1.875rem !important;
  }
  ._ms-pt-xl {
    padding-top: 2.5rem !important;
  }
  ._ms-pt-mg {
    padding-top: 3.125rem !important;
  }
  ._ms-pr-sm {
    padding-right: 0.3125rem !important;
  }
  ._ms-pr-md {
    padding-right: 0.625rem !important;
  }
  ._ms-pr-def {
    padding-right: 1.25rem !important;
  }
  ._ms-pr-lg {
    padding-right: 1.875rem !important;
  }
  ._ms-pr-xl {
    padding-right: 2.5rem !important;
  }
  ._ms-pr-mg {
    padding-right: 3.125rem !important;
  }
  ._ms-pb-sm {
    padding-bottom: 0.3125rem !important;
  }
  ._ms-pb-md {
    padding-bottom: 0.625rem !important;
  }
  ._ms-pb-def {
    padding-bottom: 1.25rem !important;
  }
  ._ms-pb-lg {
    padding-bottom: 1.875rem !important;
  }
  ._ms-pb-xl {
    padding-bottom: 2.5rem !important;
  }
  ._ms-pb-mg {
    padding-bottom: 3.125rem !important;
  }
  ._ms-pl-sm {
    padding-left: 0.3125rem !important;
  }
  ._ms-pl-md {
    padding-left: 0.625rem !important;
  }
  ._ms-pl-def {
    padding-left: 1.25rem !important;
  }
  ._ms-pl-lg {
    padding-left: 1.875rem !important;
  }
  ._ms-pl-xl {
    padding-left: 2.5rem !important;
  }
  ._ms-pl-mg {
    padding-left: 3.125rem !important;
  }
  ._ms-m-auto {
    margin: auto !important;
  }
  ._ms-ml-auto {
    margin-left: auto !important;
  }
  ._ms-mr-auto {
    margin-right: auto !important;
  }
  ._ms-mlr-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 768px) {
  ._md-m-sm {
    margin: 0.3125rem !important;
  }
  ._md-mtb-sm {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  ._md-mlr-sm {
    margin-left: 0.3125rem !important;
    margin-right: 0.3125rem !important;
  }
  ._md-m-md {
    margin: 0.625rem !important;
  }
  ._md-mtb-md {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  ._md-mlr-md {
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }
  ._md-m-def {
    margin: 1.25rem !important;
  }
  ._md-mtb-def {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  ._md-mlr-def {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  ._md-m-lg {
    margin: 1.875rem !important;
  }
  ._md-mtb-lg {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  ._md-mlr-lg {
    margin-left: 1.875rem !important;
    margin-right: 1.875rem !important;
  }
  ._md-m-xl {
    margin: 2.5rem !important;
  }
  ._md-mtb-xl {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  ._md-mlr-xl {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  ._md-m-mg {
    margin: 3.125rem !important;
  }
  ._md-mtb-mg {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  ._md-mlr-mg {
    margin-left: 3.125rem !important;
    margin-right: 3.125rem !important;
  }
  ._md-mt-sm {
    margin-top: 0.3125rem !important;
  }
  ._md-mt-md {
    margin-top: 0.625rem !important;
  }
  ._md-mt-def {
    margin-top: 1.25rem !important;
  }
  ._md-mt-lg {
    margin-top: 1.875rem !important;
  }
  ._md-mt-xl {
    margin-top: 2.5rem !important;
  }
  ._md-mt-mg {
    margin-top: 3.125rem !important;
  }
  ._md-mr-sm {
    margin-right: 0.3125rem !important;
  }
  ._md-mr-md {
    margin-right: 0.625rem !important;
  }
  ._md-mr-def {
    margin-right: 1.25rem !important;
  }
  ._md-mr-lg {
    margin-right: 1.875rem !important;
  }
  ._md-mr-xl {
    margin-right: 2.5rem !important;
  }
  ._md-mr-mg {
    margin-right: 3.125rem !important;
  }
  ._md-mb-sm {
    margin-bottom: 0.3125rem !important;
  }
  ._md-mb-md {
    margin-bottom: 0.625rem !important;
  }
  ._md-mb-def {
    margin-bottom: 1.25rem !important;
  }
  ._md-mb-lg {
    margin-bottom: 1.875rem !important;
  }
  ._md-mb-xl {
    margin-bottom: 2.5rem !important;
  }
  ._md-mb-mg {
    margin-bottom: 3.125rem !important;
  }
  ._md-ml-sm {
    margin-left: 0.3125rem !important;
  }
  ._md-ml-md {
    margin-left: 0.625rem !important;
  }
  ._md-ml-def {
    margin-left: 1.25rem !important;
  }
  ._md-ml-lg {
    margin-left: 1.875rem !important;
  }
  ._md-ml-xl {
    margin-left: 2.5rem !important;
  }
  ._md-ml-mg {
    margin-left: 3.125rem !important;
  }
  ._md-p-sm {
    padding: 0.3125rem !important;
  }
  ._md-ptb-sm {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  ._md-plr-sm {
    padding-left: 0.3125rem !important;
    padding-right: 0.3125rem !important;
  }
  ._md-p-md {
    padding: 0.625rem !important;
  }
  ._md-ptb-md {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  ._md-plr-md {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  ._md-p-def {
    padding: 1.25rem !important;
  }
  ._md-ptb-def {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  ._md-plr-def {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  ._md-p-lg {
    padding: 1.875rem !important;
  }
  ._md-ptb-lg {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  ._md-plr-lg {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  ._md-p-xl {
    padding: 2.5rem !important;
  }
  ._md-ptb-xl {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  ._md-plr-xl {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  ._md-p-mg {
    padding: 3.125rem !important;
  }
  ._md-ptb-mg {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  ._md-plr-mg {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  ._md-pt-sm {
    padding-top: 0.3125rem !important;
  }
  ._md-pt-md {
    padding-top: 0.625rem !important;
  }
  ._md-pt-def {
    padding-top: 1.25rem !important;
  }
  ._md-pt-lg {
    padding-top: 1.875rem !important;
  }
  ._md-pt-xl {
    padding-top: 2.5rem !important;
  }
  ._md-pt-mg {
    padding-top: 3.125rem !important;
  }
  ._md-pr-sm {
    padding-right: 0.3125rem !important;
  }
  ._md-pr-md {
    padding-right: 0.625rem !important;
  }
  ._md-pr-def {
    padding-right: 1.25rem !important;
  }
  ._md-pr-lg {
    padding-right: 1.875rem !important;
  }
  ._md-pr-xl {
    padding-right: 2.5rem !important;
  }
  ._md-pr-mg {
    padding-right: 3.125rem !important;
  }
  ._md-pb-sm {
    padding-bottom: 0.3125rem !important;
  }
  ._md-pb-md {
    padding-bottom: 0.625rem !important;
  }
  ._md-pb-def {
    padding-bottom: 1.25rem !important;
  }
  ._md-pb-lg {
    padding-bottom: 1.875rem !important;
  }
  ._md-pb-xl {
    padding-bottom: 2.5rem !important;
  }
  ._md-pb-mg {
    padding-bottom: 3.125rem !important;
  }
  ._md-pl-sm {
    padding-left: 0.3125rem !important;
  }
  ._md-pl-md {
    padding-left: 0.625rem !important;
  }
  ._md-pl-def {
    padding-left: 1.25rem !important;
  }
  ._md-pl-lg {
    padding-left: 1.875rem !important;
  }
  ._md-pl-xl {
    padding-left: 2.5rem !important;
  }
  ._md-pl-mg {
    padding-left: 3.125rem !important;
  }
  ._md-m-auto {
    margin: auto !important;
  }
  ._md-ml-auto {
    margin-left: auto !important;
  }
  ._md-mr-auto {
    margin-right: auto !important;
  }
  ._md-mlr-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1024px) {
  ._def-m-sm {
    margin: 0.3125rem !important;
  }
  ._def-mtb-sm {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  ._def-mlr-sm {
    margin-left: 0.3125rem !important;
    margin-right: 0.3125rem !important;
  }
  ._def-m-md {
    margin: 0.625rem !important;
  }
  ._def-mtb-md {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  ._def-mlr-md {
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }
  ._def-m-def {
    margin: 1.25rem !important;
  }
  ._def-mtb-def {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  ._def-mlr-def {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  ._def-m-lg {
    margin: 1.875rem !important;
  }
  ._def-mtb-lg {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  ._def-mlr-lg {
    margin-left: 1.875rem !important;
    margin-right: 1.875rem !important;
  }
  ._def-m-xl {
    margin: 2.5rem !important;
  }
  ._def-mtb-xl {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  ._def-mlr-xl {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  ._def-m-mg {
    margin: 3.125rem !important;
  }
  ._def-mtb-mg {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  ._def-mlr-mg {
    margin-left: 3.125rem !important;
    margin-right: 3.125rem !important;
  }
  ._def-mt-sm {
    margin-top: 0.3125rem !important;
  }
  ._def-mt-md {
    margin-top: 0.625rem !important;
  }
  ._def-mt-def {
    margin-top: 1.25rem !important;
  }
  ._def-mt-lg {
    margin-top: 1.875rem !important;
  }
  ._def-mt-xl {
    margin-top: 2.5rem !important;
  }
  ._def-mt-mg {
    margin-top: 3.125rem !important;
  }
  ._def-mr-sm {
    margin-right: 0.3125rem !important;
  }
  ._def-mr-md {
    margin-right: 0.625rem !important;
  }
  ._def-mr-def {
    margin-right: 1.25rem !important;
  }
  ._def-mr-lg {
    margin-right: 1.875rem !important;
  }
  ._def-mr-xl {
    margin-right: 2.5rem !important;
  }
  ._def-mr-mg {
    margin-right: 3.125rem !important;
  }
  ._def-mb-sm {
    margin-bottom: 0.3125rem !important;
  }
  ._def-mb-md {
    margin-bottom: 0.625rem !important;
  }
  ._def-mb-def {
    margin-bottom: 1.25rem !important;
  }
  ._def-mb-lg {
    margin-bottom: 1.875rem !important;
  }
  ._def-mb-xl {
    margin-bottom: 2.5rem !important;
  }
  ._def-mb-mg {
    margin-bottom: 3.125rem !important;
  }
  ._def-ml-sm {
    margin-left: 0.3125rem !important;
  }
  ._def-ml-md {
    margin-left: 0.625rem !important;
  }
  ._def-ml-def {
    margin-left: 1.25rem !important;
  }
  ._def-ml-lg {
    margin-left: 1.875rem !important;
  }
  ._def-ml-xl {
    margin-left: 2.5rem !important;
  }
  ._def-ml-mg {
    margin-left: 3.125rem !important;
  }
  ._def-p-sm {
    padding: 0.3125rem !important;
  }
  ._def-ptb-sm {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  ._def-plr-sm {
    padding-left: 0.3125rem !important;
    padding-right: 0.3125rem !important;
  }
  ._def-p-md {
    padding: 0.625rem !important;
  }
  ._def-ptb-md {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  ._def-plr-md {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  ._def-p-def {
    padding: 1.25rem !important;
  }
  ._def-ptb-def {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  ._def-plr-def {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  ._def-p-lg {
    padding: 1.875rem !important;
  }
  ._def-ptb-lg {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  ._def-plr-lg {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  ._def-p-xl {
    padding: 2.5rem !important;
  }
  ._def-ptb-xl {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  ._def-plr-xl {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  ._def-p-mg {
    padding: 3.125rem !important;
  }
  ._def-ptb-mg {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  ._def-plr-mg {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  ._def-pt-sm {
    padding-top: 0.3125rem !important;
  }
  ._def-pt-md {
    padding-top: 0.625rem !important;
  }
  ._def-pt-def {
    padding-top: 1.25rem !important;
  }
  ._def-pt-lg {
    padding-top: 1.875rem !important;
  }
  ._def-pt-xl {
    padding-top: 2.5rem !important;
  }
  ._def-pt-mg {
    padding-top: 3.125rem !important;
  }
  ._def-pr-sm {
    padding-right: 0.3125rem !important;
  }
  ._def-pr-md {
    padding-right: 0.625rem !important;
  }
  ._def-pr-def {
    padding-right: 1.25rem !important;
  }
  ._def-pr-lg {
    padding-right: 1.875rem !important;
  }
  ._def-pr-xl {
    padding-right: 2.5rem !important;
  }
  ._def-pr-mg {
    padding-right: 3.125rem !important;
  }
  ._def-pb-sm {
    padding-bottom: 0.3125rem !important;
  }
  ._def-pb-md {
    padding-bottom: 0.625rem !important;
  }
  ._def-pb-def {
    padding-bottom: 1.25rem !important;
  }
  ._def-pb-lg {
    padding-bottom: 1.875rem !important;
  }
  ._def-pb-xl {
    padding-bottom: 2.5rem !important;
  }
  ._def-pb-mg {
    padding-bottom: 3.125rem !important;
  }
  ._def-pl-sm {
    padding-left: 0.3125rem !important;
  }
  ._def-pl-md {
    padding-left: 0.625rem !important;
  }
  ._def-pl-def {
    padding-left: 1.25rem !important;
  }
  ._def-pl-lg {
    padding-left: 1.875rem !important;
  }
  ._def-pl-xl {
    padding-left: 2.5rem !important;
  }
  ._def-pl-mg {
    padding-left: 3.125rem !important;
  }
  ._def-m-auto {
    margin: auto !important;
  }
  ._def-ml-auto {
    margin-left: auto !important;
  }
  ._def-mr-auto {
    margin-right: auto !important;
  }
  ._def-mlr-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1280px) {
  ._lg-m-sm {
    margin: 0.3125rem !important;
  }
  ._lg-mtb-sm {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  ._lg-mlr-sm {
    margin-left: 0.3125rem !important;
    margin-right: 0.3125rem !important;
  }
  ._lg-m-md {
    margin: 0.625rem !important;
  }
  ._lg-mtb-md {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  ._lg-mlr-md {
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }
  ._lg-m-def {
    margin: 1.25rem !important;
  }
  ._lg-mtb-def {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  ._lg-mlr-def {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  ._lg-m-lg {
    margin: 1.875rem !important;
  }
  ._lg-mtb-lg {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  ._lg-mlr-lg {
    margin-left: 1.875rem !important;
    margin-right: 1.875rem !important;
  }
  ._lg-m-xl {
    margin: 2.5rem !important;
  }
  ._lg-mtb-xl {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  ._lg-mlr-xl {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  ._lg-m-mg {
    margin: 3.125rem !important;
  }
  ._lg-mtb-mg {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  ._lg-mlr-mg {
    margin-left: 3.125rem !important;
    margin-right: 3.125rem !important;
  }
  ._lg-mt-sm {
    margin-top: 0.3125rem !important;
  }
  ._lg-mt-md {
    margin-top: 0.625rem !important;
  }
  ._lg-mt-def {
    margin-top: 1.25rem !important;
  }
  ._lg-mt-lg {
    margin-top: 1.875rem !important;
  }
  ._lg-mt-xl {
    margin-top: 2.5rem !important;
  }
  ._lg-mt-mg {
    margin-top: 3.125rem !important;
  }
  ._lg-mr-sm {
    margin-right: 0.3125rem !important;
  }
  ._lg-mr-md {
    margin-right: 0.625rem !important;
  }
  ._lg-mr-def {
    margin-right: 1.25rem !important;
  }
  ._lg-mr-lg {
    margin-right: 1.875rem !important;
  }
  ._lg-mr-xl {
    margin-right: 2.5rem !important;
  }
  ._lg-mr-mg {
    margin-right: 3.125rem !important;
  }
  ._lg-mb-sm {
    margin-bottom: 0.3125rem !important;
  }
  ._lg-mb-md {
    margin-bottom: 0.625rem !important;
  }
  ._lg-mb-def {
    margin-bottom: 1.25rem !important;
  }
  ._lg-mb-lg {
    margin-bottom: 1.875rem !important;
  }
  ._lg-mb-xl {
    margin-bottom: 2.5rem !important;
  }
  ._lg-mb-mg {
    margin-bottom: 3.125rem !important;
  }
  ._lg-ml-sm {
    margin-left: 0.3125rem !important;
  }
  ._lg-ml-md {
    margin-left: 0.625rem !important;
  }
  ._lg-ml-def {
    margin-left: 1.25rem !important;
  }
  ._lg-ml-lg {
    margin-left: 1.875rem !important;
  }
  ._lg-ml-xl {
    margin-left: 2.5rem !important;
  }
  ._lg-ml-mg {
    margin-left: 3.125rem !important;
  }
  ._lg-p-sm {
    padding: 0.3125rem !important;
  }
  ._lg-ptb-sm {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  ._lg-plr-sm {
    padding-left: 0.3125rem !important;
    padding-right: 0.3125rem !important;
  }
  ._lg-p-md {
    padding: 0.625rem !important;
  }
  ._lg-ptb-md {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  ._lg-plr-md {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  ._lg-p-def {
    padding: 1.25rem !important;
  }
  ._lg-ptb-def {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  ._lg-plr-def {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  ._lg-p-lg {
    padding: 1.875rem !important;
  }
  ._lg-ptb-lg {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  ._lg-plr-lg {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  ._lg-p-xl {
    padding: 2.5rem !important;
  }
  ._lg-ptb-xl {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  ._lg-plr-xl {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  ._lg-p-mg {
    padding: 3.125rem !important;
  }
  ._lg-ptb-mg {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  ._lg-plr-mg {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  ._lg-pt-sm {
    padding-top: 0.3125rem !important;
  }
  ._lg-pt-md {
    padding-top: 0.625rem !important;
  }
  ._lg-pt-def {
    padding-top: 1.25rem !important;
  }
  ._lg-pt-lg {
    padding-top: 1.875rem !important;
  }
  ._lg-pt-xl {
    padding-top: 2.5rem !important;
  }
  ._lg-pt-mg {
    padding-top: 3.125rem !important;
  }
  ._lg-pr-sm {
    padding-right: 0.3125rem !important;
  }
  ._lg-pr-md {
    padding-right: 0.625rem !important;
  }
  ._lg-pr-def {
    padding-right: 1.25rem !important;
  }
  ._lg-pr-lg {
    padding-right: 1.875rem !important;
  }
  ._lg-pr-xl {
    padding-right: 2.5rem !important;
  }
  ._lg-pr-mg {
    padding-right: 3.125rem !important;
  }
  ._lg-pb-sm {
    padding-bottom: 0.3125rem !important;
  }
  ._lg-pb-md {
    padding-bottom: 0.625rem !important;
  }
  ._lg-pb-def {
    padding-bottom: 1.25rem !important;
  }
  ._lg-pb-lg {
    padding-bottom: 1.875rem !important;
  }
  ._lg-pb-xl {
    padding-bottom: 2.5rem !important;
  }
  ._lg-pb-mg {
    padding-bottom: 3.125rem !important;
  }
  ._lg-pl-sm {
    padding-left: 0.3125rem !important;
  }
  ._lg-pl-md {
    padding-left: 0.625rem !important;
  }
  ._lg-pl-def {
    padding-left: 1.25rem !important;
  }
  ._lg-pl-lg {
    padding-left: 1.875rem !important;
  }
  ._lg-pl-xl {
    padding-left: 2.5rem !important;
  }
  ._lg-pl-mg {
    padding-left: 3.125rem !important;
  }
  ._lg-m-auto {
    margin: auto !important;
  }
  ._lg-ml-auto {
    margin-left: auto !important;
  }
  ._lg-mr-auto {
    margin-right: auto !important;
  }
  ._lg-mlr-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1366px) {
  ._xl-m-sm {
    margin: 0.3125rem !important;
  }
  ._xl-mtb-sm {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  ._xl-mlr-sm {
    margin-left: 0.3125rem !important;
    margin-right: 0.3125rem !important;
  }
  ._xl-m-md {
    margin: 0.625rem !important;
  }
  ._xl-mtb-md {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  ._xl-mlr-md {
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }
  ._xl-m-def {
    margin: 1.25rem !important;
  }
  ._xl-mtb-def {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  ._xl-mlr-def {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  ._xl-m-lg {
    margin: 1.875rem !important;
  }
  ._xl-mtb-lg {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  ._xl-mlr-lg {
    margin-left: 1.875rem !important;
    margin-right: 1.875rem !important;
  }
  ._xl-m-xl {
    margin: 2.5rem !important;
  }
  ._xl-mtb-xl {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  ._xl-mlr-xl {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  ._xl-m-mg {
    margin: 3.125rem !important;
  }
  ._xl-mtb-mg {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  ._xl-mlr-mg {
    margin-left: 3.125rem !important;
    margin-right: 3.125rem !important;
  }
  ._xl-mt-sm {
    margin-top: 0.3125rem !important;
  }
  ._xl-mt-md {
    margin-top: 0.625rem !important;
  }
  ._xl-mt-def {
    margin-top: 1.25rem !important;
  }
  ._xl-mt-lg {
    margin-top: 1.875rem !important;
  }
  ._xl-mt-xl {
    margin-top: 2.5rem !important;
  }
  ._xl-mt-mg {
    margin-top: 3.125rem !important;
  }
  ._xl-mr-sm {
    margin-right: 0.3125rem !important;
  }
  ._xl-mr-md {
    margin-right: 0.625rem !important;
  }
  ._xl-mr-def {
    margin-right: 1.25rem !important;
  }
  ._xl-mr-lg {
    margin-right: 1.875rem !important;
  }
  ._xl-mr-xl {
    margin-right: 2.5rem !important;
  }
  ._xl-mr-mg {
    margin-right: 3.125rem !important;
  }
  ._xl-mb-sm {
    margin-bottom: 0.3125rem !important;
  }
  ._xl-mb-md {
    margin-bottom: 0.625rem !important;
  }
  ._xl-mb-def {
    margin-bottom: 1.25rem !important;
  }
  ._xl-mb-lg {
    margin-bottom: 1.875rem !important;
  }
  ._xl-mb-xl {
    margin-bottom: 2.5rem !important;
  }
  ._xl-mb-mg {
    margin-bottom: 3.125rem !important;
  }
  ._xl-ml-sm {
    margin-left: 0.3125rem !important;
  }
  ._xl-ml-md {
    margin-left: 0.625rem !important;
  }
  ._xl-ml-def {
    margin-left: 1.25rem !important;
  }
  ._xl-ml-lg {
    margin-left: 1.875rem !important;
  }
  ._xl-ml-xl {
    margin-left: 2.5rem !important;
  }
  ._xl-ml-mg {
    margin-left: 3.125rem !important;
  }
  ._xl-p-sm {
    padding: 0.3125rem !important;
  }
  ._xl-ptb-sm {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  ._xl-plr-sm {
    padding-left: 0.3125rem !important;
    padding-right: 0.3125rem !important;
  }
  ._xl-p-md {
    padding: 0.625rem !important;
  }
  ._xl-ptb-md {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  ._xl-plr-md {
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }
  ._xl-p-def {
    padding: 1.25rem !important;
  }
  ._xl-ptb-def {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  ._xl-plr-def {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  ._xl-p-lg {
    padding: 1.875rem !important;
  }
  ._xl-ptb-lg {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  ._xl-plr-lg {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }
  ._xl-p-xl {
    padding: 2.5rem !important;
  }
  ._xl-ptb-xl {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  ._xl-plr-xl {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  ._xl-p-mg {
    padding: 3.125rem !important;
  }
  ._xl-ptb-mg {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  ._xl-plr-mg {
    padding-left: 3.125rem !important;
    padding-right: 3.125rem !important;
  }
  ._xl-pt-sm {
    padding-top: 0.3125rem !important;
  }
  ._xl-pt-md {
    padding-top: 0.625rem !important;
  }
  ._xl-pt-def {
    padding-top: 1.25rem !important;
  }
  ._xl-pt-lg {
    padding-top: 1.875rem !important;
  }
  ._xl-pt-xl {
    padding-top: 2.5rem !important;
  }
  ._xl-pt-mg {
    padding-top: 3.125rem !important;
  }
  ._xl-pr-sm {
    padding-right: 0.3125rem !important;
  }
  ._xl-pr-md {
    padding-right: 0.625rem !important;
  }
  ._xl-pr-def {
    padding-right: 1.25rem !important;
  }
  ._xl-pr-lg {
    padding-right: 1.875rem !important;
  }
  ._xl-pr-xl {
    padding-right: 2.5rem !important;
  }
  ._xl-pr-mg {
    padding-right: 3.125rem !important;
  }
  ._xl-pb-sm {
    padding-bottom: 0.3125rem !important;
  }
  ._xl-pb-md {
    padding-bottom: 0.625rem !important;
  }
  ._xl-pb-def {
    padding-bottom: 1.25rem !important;
  }
  ._xl-pb-lg {
    padding-bottom: 1.875rem !important;
  }
  ._xl-pb-xl {
    padding-bottom: 2.5rem !important;
  }
  ._xl-pb-mg {
    padding-bottom: 3.125rem !important;
  }
  ._xl-pl-sm {
    padding-left: 0.3125rem !important;
  }
  ._xl-pl-md {
    padding-left: 0.625rem !important;
  }
  ._xl-pl-def {
    padding-left: 1.25rem !important;
  }
  ._xl-pl-lg {
    padding-left: 1.875rem !important;
  }
  ._xl-pl-xl {
    padding-left: 2.5rem !important;
  }
  ._xl-pl-mg {
    padding-left: 3.125rem !important;
  }
  ._xl-m-auto {
    margin: auto !important;
  }
  ._xl-ml-auto {
    margin-left: auto !important;
  }
  ._xl-mr-auto {
    margin-right: auto !important;
  }
  ._xl-mlr-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
