.slick {
  &-arrow {}
  
  &-prev,
  &-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    display: block;
    width: 44px;
    height: 44px;
    background-color: transparent;
    border-radius: 50%;
    font-size: 0;
    transition: transform .3s ease;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      display: block;
      width: 50%;
      height: 50%;
    }
    
    &:active {
      transform: translateY(calc(-50% + 7px));
    }
  }
  
  &-prev {
    left: 0;
    border: 2px solid #36b789;
    
    &:before {
      left: calc(50% + 5px);
      border-width: 2px;
      border-style: solid;
      border-color: transparent transparent #01b6ef #01b6ef;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    
    &.slick-disabled {
      transform: translateX(-100px);
    }
  }
  
  &-next {
    right: 0;
    border: 2px solid #01b6ef;
    
    &:after {
      left: calc(50% - 5px);
      border-width: 2px;
      border-style: solid;
      border-color: transparent #36b789 #36b789 transparent;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    
    &.slick-disabled {
      transform: translateX(100px);
    }
  }
}