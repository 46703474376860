.link {
  display: inline-block;
  vertical-align: middle;
  transition: color .3s ease;
  
  &--phone {
    color: #fff;
    font-size: rem(20);
    font-weight: 700;
    text-decoration: none;
    letter-spacing: 1px;
    
    &:hover {
      text-decoration: none;
    }
  }
}