//$color-success: #43b86f;
$color-error: #fc171f;

.control {
	position: relative;

	//&.has-success {
	//  .control__input,
	//  .control__textarea {
	//    color: #fff;
	//    border-color: $color-success;
	//    background-color: rgba($color-success, .7);
	//
	//    &::placeholder {
	//      color: #fff;
	//      color: rgba(#fff, .5);
	//    }
	//  }
	//}

	&.has-error {

		.control__input,
		.control__textarea {
			color: #fff;
			border-color: $color-error;
			background-color: rgba($color-error, .5);

			&::placeholder {
				color: #fff;
				color: rgba(#fff, .5);
			}
		}

		label.has-error {
			position: absolute;
			bottom: 100%;
			left: 8px;
			right: 8px;
			z-index: auto;
			transform: translateY(50%) scaleY(1);
			display: block;
			padding: rem(6) rem(7) rem(5);
			border-radius: 4px;
			background-color: rgba($color-error, 7);
			color: #fff;
			text-shadow: 1px 1px 0 #353535;
			font-size: rem(13.5);
			font-weight: 100;
			letter-spacing: .5px;
			cursor: pointer;
			opacity: 1;
			transform-origin: center;
			transition: opacity .3s ease, transform .3s ease;

			@include media($def) {
				font-size: rem(12);
			}
		}
	}

	&.mask-success {
		.mask-country-for {
			position: absolute;
			top: 100%;
			left: 8px;
			right: 8px;
			z-index: auto;
			transform: translateY(-50%) scaleY(1);
			display: block;
			padding: rem(6) rem(7) rem(5);
			border-radius: 4px;
			background-color: #2bb8ac;
			color: #fff;
			text-shadow: 0 0 10px #353535;
			font-size: rem(13.5);
			font-weight: 100;
			letter-spacing: 1px;
			cursor: pointer;
			opacity: 1;
			transform-origin: center;
			transition: opacity .3s ease, transform .3s ease;

			@include media($def) {
				font-size: rem(13);
			}
		}
	}

	&__input,
	&__textarea {
		display: block;
		width: 100%;
		padding: rem(14);
		border: 2px solid transparent;
		border-image-slice: 2;
		border-radius: 7px;
		background-color: #f3f3f3;
		color: #353535;
		font-size: rem(16);
		font-weight: 300;
		line-height: 1.4;
		letter-spacing: .5px;
		transition: .3s ease;

		&::placeholder {
			display: block;
			color: #353535;
			font-size: rem(16);
			font-weight: 300;
			letter-spacing: .5px;
		}

		&:focus {
			background-color: rgba(#333, .5) !important;
			color: #fff !important;
			border-color: #43b86f !important;
			border-image-source: linear-gradient(to right, rgb(67, 184, 111) 30%, rgb(0, 181, 239)) !important;

			&::placeholder {
				color: #fff !important;
				color: rgba(#fff, .5) !important;
			}

			& + label.has-error {
				transform: translateY(50%) scaleY(0);
				opacity: 0;
			}
		}
	}

	&__textarea {
		resize: vertical;

		.popup--question &,
		.contacts & {
			height: 250px;

			@include media($md) {
				min-height: initial;
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				height: 100%;

				.ios &,
				.safari &,
				.mac &,
				.maclike &,
				.ipad &,
				.iphone & {
					display: block;
					height: 290px;

					@include media($lg) {
						height: 276px;
					}
				}
			}
		}
	}

	&__submit {
	}

	&--input {
	}

	&--textarea {
		@include media($md) {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			height: 100%;
		}
	}

	&--submit {
	}

	&--required {
	}

}