.question-wrap {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
  order: 1;
  margin-top: 10px;
  text-align: right;

  @include media(560px, max) {
    margin-top: 0;
  }
  
  @include media(1560px) {
    width: initial;
    max-width: initial;
    flex-basis: initial;
    order: initial;
    margin-top: initial;
    text-align: initial;
  }
}