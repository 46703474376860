.about-vid {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 280px;
  min-height: 100px;
  background-color: #000;
  transform: translateY(100%);
  transition: transform .3s ease;
  
  &__title {
    display: block;
    margin: rem(25) 0;
    padding: 0 10px;
    color: #fff;
    font-size: rem(16);
    font-weight: 400;
    text-align: center;
    letter-spacing: .5px;
  }
  
  &__show-video {
    display: block;
    max-width: 48px;
    height: auto;
    margin: 0 auto;
    border: none;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    transition: opacity .3s ease, transform .3s ease;
    animation: blink 3s infinite;

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }

    &:hover {
      animation-play-state: paused;
      transform: scale(1.1);
      text-decoration: none;
    }
  }
  
  &__seen-already {
    display: block;
    margin: rem(25) 0;
    color: #fff;
    font-size: rem(14);
    font-weight: 400;
    text-align: center;
    letter-spacing: .5px;
    opacity: .5;
    cursor: pointer;
    transition: opacity .3s ease;
    
    & > span {
      display: inline-block;
      border-bottom: 1px solid #fff;
    }
    
    &:hover {
      opacity: .9;
    }
  }
  
  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    display: block;
    width: 10px;
    height: 10px;
    padding: 0;
    border: none;
    background-color: transparent;
    fill: #fff;
    cursor: pointer;
    
    svg {
      display: block;
      width: 10px;
      height: 10px;
    }
  }
  
  &.is-visible {
    transform: translateY(0) !important;
  }
}