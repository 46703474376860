.table {
  &__row {}
  
  &__cell {}
  
  
  
  &--contacts {
    
    .table {
      &__row {
        padding: rem(45) 0 rem(42);
        background-color: #f3f3f3;
        border-bottom: 1px solid #e5e5e5;
        
        & > .view-size {
          & > .grid {
            @include media($def) {
              flex-wrap: nowrap;
            }
          }
        }
      }
      
      &__cell {
        .link {
          color: #353535;
          font-size: rem(16);
          font-weight: 300;
          text-decoration: none;
          
          &--phone {
            display: block;
            white-space: nowrap;
            
            & + .link--phone {
              margin-top: 6px;
              
              @include media($def) {
                margin-top: 0;
              }
            }
          }
          
          &:hover:not(.link--map) {
            text-decoration: underline;
          }
        }
        
        .media--phone {
          white-space: nowrap;
        }
        
        &--nth-1 {
          width: 50%;
          text-align: right;
          color: #353535;
          font-size: rem(16);
          font-weight: 700;
          text-transform: uppercase;
          
          @include media($def) {
            width: calc(31% / 2);
            text-align: initial;
          }
        }
        
        &--nth-2 {
          width: 50%;
          color: #353535;
          font-size: rem(16);
          font-weight: 300;
          
          @include media($def) {
            width: calc(31% / 2);
            text-align: center;
          }
        }
        
        &--nth-3 {
          width: 100%;
          
          .media {
            max-width: 300px;
            margin: 0 auto;
          }
          
          @include media($md) {
            width: 33%;
          }
          
          @include media($def) {
            width: calc(69% / 3);
          }
        }
        
        &--nth-4 {
          width: 100%;
          
          .media {
            max-width: 300px;
            margin: 0 auto;
          }
          
          @include media($md) {
            width: 33%;
          }
          
          @include media($def) {
            width: calc(69% / 3);
          }
        }
        
        &--nth-5 {
          width: 100%;
          
          .media {
            max-width: 300px;
            margin: 0 auto;
          }
          
          @include media($md) {
            width: 33%;
          }
          
          @include media($def) {
            width: calc(69% / 3);
          }
        }
      }
    }
  }
}